import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared';
import { PhoneVerificationComponent } from './phone-verification/phone-verification.component';
import { AuthenicationPopupComponent } from './authenication-popup/authenication-popup.component';

@NgModule({
  declarations: [LoginComponent, PhoneVerificationComponent,AuthenicationPopupComponent],
  imports: [
    SharedModule
  ],
  exports: [
    LoginComponent,
    PhoneVerificationComponent,
    AuthenicationPopupComponent
  ]
})
export class UserModule { }
