import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginService, SalesforceService } from 'src/app/core';
import { TaskService } from 'src/app/shared/services/task.service';
import { CryptoService } from 'src/app/shared/services/crytoservice/crypto-service.service';
import { TabServiceService } from 'src/app/shared/services/tabs/tab-service.service';

import { environment } from 'src/environments/environment';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenicationPopupComponent } from '../authenication-popup/authenication-popup.component';
import { UserRoles } from 'src/app/shared/constants/roles.constant';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { PortalSettingsService } from 'src/app/shared/services/global-settings/portal-settings.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('dataContainer') dataContainer: ElementRef;

  loginForm: UntypedFormGroup;
  verifyForm: UntypedFormGroup;
  loading = false;
  hasError = false;
  hasErrorWithLoginVerify = false;
  hasErrorWithLoginPhoneNo = false;
  hasErrorWithLoginCount = false;

  showprogress = false;

  hasErrorCode = false;
  hasErrorWithVerify = false;
  hasErrorWithPhoneNo = false;
  hasErrorWithCount = false;
  showLoginForm = true;
  showVerifyForm = false;
  isprovider: any;

  userRole;
  roleDataArray: any;
  maskedPhone: string;
  userInfo;
  phoneNumberRefined;
  userInfoData;
  bgImagesArray: any = [];
  selecteImage = '';
  intervalFunction: any;
  imagaePath = '';
  clientIP = '';
  imgText = '';
  imgPos = '';
  globalColor:any =[];
  newUrl = 'https://rosewellness.360intellect.com';
  getYear = new Date().getFullYear();  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private loginSvc: LoginService,
    private sfSvc: SalesforceService,
    private tabsSvc: TabServiceService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private portalSettingSvc: PortalSettingsService,
  ) {}

  ngOnInit(): void {
    this.imagaePath = environment.IMAGE_PATH;
    this.loadLoginImages();
    this.intervalFunction = setInterval(() => {
      this.getRandomImage();
    }, 60000);
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      loginAs: ['provider', Validators.required],
    });



//  this.http.get("http://api.ipify.org/?format=json/").subscribe((res:any)=>{
//  console.log("46565456",res.ip);

//    this.clientIP = res.ip;
//    console.log("this.clientIP",this.clientIP)
//  });

// this.http.get('https://api.db-ip.com/v2/free/self/').subscribe((res:any)=> {
//   console.log(JSON.stringify(res, null, 2));

//   this.clientIP = res.ipAddress;
// });

  this.geClientIP();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.hasError = false;
    this.loading = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loginSvc
      .login(this.f.username.value, this.f.password.value, this.f.loginAs.value)
      .subscribe({
        next: (data: any) => {
          // console.log("initial data-----",data)
          if ((data && data.result) || data.records.length == 1) {
            this.showprogress = true;
            let userData;
            console.log(data)
            if (this.f.loginAs.value != 'ecommerce' && data && data.result ) {
              userData = {
                loginAs: this.f.loginAs.value,
                object:this.f.loginAs.value === 'provider'? 'OfficeUser__c': 'Patient__c',
                objectIdentifier:this.f.loginAs.value === 'provider'? 'Email__c/' + data.user_details.email_address:'Name/' + data.patient_details.id,
              };
            } else {
              userData = {
                loginAs: this.f.loginAs.value,
                object: 'OfficeUser__c',
                objectIdentifier: 'Email__c/' + data.records[0].Email__c,
              };
            }
            if (this.f.loginAs.value != 'ecommerce' && data && data.result) {
              if (this.f.loginAs.value === 'provider') {
                if (!data.user_details.email_address) {
                  this.loading = false;
                  this.showprogress = false;
                  this.openSnackBarError(
                    'Empty EmailId for this user in CERBO record',
                    ''
                  );
                  return;
                }
              } else {
                if (!data.patient_details.id) {
                  this.loading = false;
                  this.showprogress = false;
                  this.openSnackBarError(
                    'Empty PatientId for this user in CERBO record',
                    ''
                  );
                  return;
                }
              }
            }
            return this.sfSvc.getSFUserObject(userData).subscribe({
              next: async (data: any) => {
                    //console.log("SF data-----",data)
                this.perpereLoginUserDetailsData(data);
                if (data && !data.errorCode) {
                  this.userInfoData = {};
                  if (userData.loginAs === 'provider') {
                    TaskService.task().isPatient(false);
                    TaskService.task(data);
                    this.isprovider = true;
                    (await this.tabsSvc.getAllTabs()).subscribe(() => {
                      localStorage.setItem(
                        'user',
                        CryptoService.encryptUsingAES256(
                          TaskService.task().userTask().name_c()
                        )
                      );
                      localStorage.setItem(
                        'userole',
                        CryptoService.encryptUsingAES256(UserRoles.PROVIDER)
                      );
                      localStorage.setItem(
                        'userId', CryptoService.encryptUsingAES256(TaskService.task().userTask().id())
                      );
                      sessionStorage.setItem(
                        'userData',
                        CryptoService.encryptUsingAES256(JSON.stringify(data))
                      );
                      let userInfo;
                      this.sfSvc.getRoleData().subscribe((roledata) => {
                        this.roleDataArray = roledata['records'];
                        if (this.roleDataArray.length) {
                          this.roleDataArray.forEach((element) => {
                            if (data.Staff__c == element.Role__c) {
                              this.userInfoData['role'] = element.Role__c;
                              this.userInfoData['twilioActive'] =
                                element.Twilio_2FA__c;
                            }
                          });

                         // console.log(data)//"(703) 334-1548"
                          if (
                            environment.IS_AUTHENTICATE &&
                            this.userInfoData.role == UserRoles.ADMIN &&
                            this.userInfoData.twilioActive == true
                          ) {
                            if (
                              data.Mobile_Phone__c != '' ||
                              data.Mobile_Phone__c != null ||
                              data.Mobile_Phone__c != undefined
                            ) {
                              let phoneNumberstr = data.Mobile_Phone__c;
                              let phoneNumberRefined = phoneNumberstr.replace(
                                /[^0-9]/g,
                                ''
                              );
                              userInfo = {
                                phonenumber: `${phoneNumberRefined}`,
                                channel: 'sms',
                                role: UserRoles.PROVIDER,
                              };
                              this.getOtp(userInfo);
                              // this.openDialog(userInfo);
                            } else {
                              this.hasErrorWithLoginPhoneNo = true;
                              this.loading = false;
                              this.showprogress = false;
                            }
                          } else {
                            if(data.Staff__c == UserRoles.OFFICE_MGR || data.Staff__c ==  UserRoles.FRONT_OFFICE){
                              this.router.navigate(['./adminDashboard/Front_Office']);
                            }else if(data.Staff__c == UserRoles.MA){
                              this.router.navigate(['./adminDashboard/subscriptions-dashboard']);
                            }else{
                            this.router.navigate(['./adminDashboard']);
                            }
                          }
                        }
                      });
                    });
                  } else if (userData.loginAs === 'ecommerce') {
                    TaskService.task().isPatient(false);
                    TaskService.task(data);
                    // console.log(data)
                    this.isprovider = true;
                    this.tabsSvc.getAllTabs().subscribe(() => {
                      localStorage.setItem(
                        'user',
                        CryptoService.encryptUsingAES256(
                          TaskService.task().userTask().name_c()
                        )
                      );
                      if(data.Staff__c == UserRoles.ECOMMERCE){
                           localStorage.setItem('userole',CryptoService.encryptUsingAES256(UserRoles.ECOMMERCE));
                      }else if(data.Staff__c == UserRoles.DEVELOPER){
                        localStorage.setItem('userole',CryptoService.encryptUsingAES256(UserRoles.DEVELOPER));
                      }
                      localStorage.setItem('userId', CryptoService.encryptUsingAES256(TaskService.task().userTask().id()));
                      sessionStorage.setItem('userData',CryptoService.encryptUsingAES256(JSON.stringify(data)));
                      let userInfo;
                      this.sfSvc.getRoleData().subscribe((roledata) => {
                        // console.log("roledata", roledata)
                        this.roleDataArray = roledata['records'];
                        if (this.roleDataArray.length) {
                          this.roleDataArray.forEach((element) => {

                            //console.log(" element.Twilio_2FA__c",  element.Twilio_2FA__c)
                            //if (data.Staff__c == element.Role__c) {
                              if(element.Twilio_2FA__c){
                              this.userInfoData['role'] = element.Role__c;
                              this.userInfoData['twilioActive'] =
                                element.Twilio_2FA__c;
                            }
                          });

                        //  console.log( this.userInfoData); 
                          
                          if (
                            environment.IS_AUTHENTICATE &&
                            this.userInfoData.role == UserRoles.ECOMMERCE &&
                            this.userInfoData.twilioActive == true
                          ) {
                            if (
                              data.Mobile_Phone__c != '' ||
                              data.Mobile_Phone__c != null ||
                              data.Mobile_Phone__c != undefined
                            ) {
                              let phoneNumberstr = data.Mobile_Phone__c;
                              let phoneNumberRefined = phoneNumberstr.replace(/[^0-9]/g,''); 

                              console.log("phoneNumberRefined", phoneNumberRefined)
                              userInfo = {
                                phonenumber: `${phoneNumberRefined}`,
                                channel: 'sms',
                                role: UserRoles.ECOMMERCE,
                              };
                              this.getOtp(userInfo);
                              // this.openDialog(userInfo);
                            } else {
                              this.hasErrorWithLoginPhoneNo = true;
                              this.loading = false;
                              this.showprogress = false;
                            }
                          } else {
                           // this.router.navigate(['./adminDashboard']);

                           if(data.Staff__c == UserRoles.ECOMMERCE){
                              this.router.navigate(['./adminDashboard/vita-living']);
                           }else if(data.Staff__c == UserRoles.ECOMMERCE_ANALYST){
                            this.router.navigate(['./adminDashboard/show-my-tasks']);
                           }else if(data.Staff__c == UserRoles.DEVELOPER){
                            this.router.navigate(['./adminDashboard/support-tickets']);
                           }
                            
                          }
                        }
                      });
                    });
                  } else {
                    TaskService.task().isPatient(true);
                    TaskService.task(data);
                    sessionStorage.setItem(
                      'userData',
                      CryptoService.encryptUsingAES256(JSON.stringify(data))
                    );
                    this.isprovider = false;
                    localStorage.setItem(
                      'user',
                      CryptoService.encryptUsingAES256(
                        TaskService.task().userTask().patientName()
                      )
                    );
                    localStorage.setItem(
                      'userrole',
                      CryptoService.encryptUsingAES256('Patient')
                    );
                    // let phoneNumberstr = data.Mobile_Phone__c;
                    // let phoneNumberRefined = phoneNumberstr.replace(/[^0-9]/g, '');
                    // let userInfo = {
                    //   // phonenumber: phoneNumberRefined,
                    //   channel: "sms"
                    // }
                    this.router.navigate(['./patientDashboard']);
                    // this.getOtp(userInfo);
                  }
                } else {
                  this.setErrorFlags();
                }
              },
              error: (err: any) => {
                this.setErrorFlags();
              },
            });

          } else {
            this.setErrorFlags();
          }
        },
        error: (error) => {
          this.setErrorFlags();
        },
      });
  }

  setErrorFlags() {
    this.hasError = true;
    this.loading = false;
    this.showprogress = false;
  }

  loadLoginImages() {
    this.loginSvc.getLoginpageImages().subscribe(
      (response) => {
        this.bgImagesArray = this.readImagenames(response['records']);
        this.getRandomImage();
      },
      (error) => {}
    );
  }

  readImagenames(imgDetailsArray) {
    let imageArray = [];
    imgDetailsArray.forEach((imgObject) => {
      imageArray.push({
        imgName: imgObject.Name + '.jpg',
        imgText: imgObject.Image_Text_1__c + imgObject.Image_Text_2__c,
        imgPos: imgObject.Position__c,
      });
    });
    return imageArray;
  }

  perpereLoginUserDetailsData(userData) {
    this.geClientIP();
    const platfromDetails = this.loginSvc.getUserPlatfromDetails();
    let userObj = {};

    userObj = {
      Source_IP__c: this.clientIP,
      Login_Time__c: new Date().toJSON(),
      Platform__c: platfromDetails.OS,
      Browser__c: platfromDetails.Browser,
      Username__c: userData.Email__c,
      Status__c: 'Success',
      OfficeUser__c: userData.Id,
     // Location__c: '',
    };

    this.loginSvc.loginHistory(userObj).subscribe(
      (resp: any) => {
        if (resp && resp.id && resp.success) {
          localStorage.setItem('loginHistoryId', resp.id);
        }
      },
      (error) => { }
    );
  }

  getOtp(userInfo) {
    // console.log("userInfo",userInfo)
    this.userInfo = userInfo;
    this.loginSvc.getOtp(userInfo).subscribe(
      (res) => {
        if (res) {
          if (
            res['to'] == `+${userInfo.phonenumber}` &&
            res['status'] == 'pending'
          ) {
            this.showprogress = false;
            this.openDialog(userInfo);
          }
        } else {
          this.hasErrorWithLoginVerify = true;
          this.loading = false;
          this.showprogress = false;
        }
      },
      (err) => {
        if (err?.error?.err?.status == 429) {
          this.hasErrorWithLoginCount = true;
          this.loading = false;
          this.showprogress = false;
        } else {
          this.hasErrorWithLoginVerify = true;
          this.loading = false;
          this.showprogress = false;
        }
      }
    );
  }

  geClientIP() {
    this.loginSvc.returnClientIP().subscribe(
      (res) => {
        this.clientIP = res['id'];
        // console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getRandomImage() {
    const ImgObj =
      this.bgImagesArray[Math.floor(Math.random() * this.bgImagesArray.length)];
    this.selecteImage = ImgObj.imgName;
    this.imgText = ImgObj.imgText;
    this.imgPos = ImgObj.imgPos;
    this.dataContainer.nativeElement.innerHTML = this.imgText;
    this.dataContainer.nativeElement.style = this.imgPos;
  }

  openDialog(userInfo): void {
    const dialogRef = this.dialog.open(AuthenicationPopupComponent, {
      panelClass: ['full-width-dialog','LoginPopup'],
      data: userInfo,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  ngOnDestroy() {
    clearInterval(this.intervalFunction);
  }
  openSnackBarError(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: ['red-snackbar'],
    });
  }

  isPortalRosewellness() {
    return window.location.hostname.includes('portal.rosewellness.com');
  }

  getCopyRights() {
    // console.log(this.portalSettingSvc.getGlobalSettingCopyRights())
     return this.portalSettingSvc.getGlobalSettingCopyRights();
   }
}
