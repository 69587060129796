import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from 'src/app/shared/services/profile/profile.service';
import { environment } from 'src/environments/environment';
import { SfInstance } from 'src/app/shared/model/sf-instance.model';
import { TaskService } from 'src/app/shared/services/task.service';
import { CryptoService } from 'src/app/shared/services/crytoservice/crypto-service.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesforceService {
  private static _sfInstance: SfInstance = null;
  patientData = new Subject();

  constructor(
    private http: HttpClient, 
    private profileSvc: ProfileService,
    private titleSvc: Title,
    private sanitizer: DomSanitizer
  ) { }

  getSFUserObject(userData: any) {
    const data = environment.SF_GET_USER_OBJECT_ENDPOINT+ userData.object + '/' + userData.objectIdentifier
    const uri = environment.NODE_SERVICE_BASE_URL+environment.NODE_SERVICE_API_END_POINT+'/execute-query';
    return this.http.post(uri, data);
  }
  getSfInstance() {
    const uri = environment.NODE_SERVICE_BASE_URL+environment.NODE_SERVICE_API_END_POINT+'/sf-instance';
    return this.http.post(uri, {});
  }

  public static sfInstance(val?: SfInstance) {
    return val ? this._sfInstance = val : this._sfInstance;
  }

  getRoleData() {
    const data = '/services/data/v50.0/query/?q='+`SELECT+Id,Name,Tabs_Access__c,KB_Category__c,Role__c,Twilio_2FA__c+FROM+Portal_Access_Control__c`;

    // SELECT Id, Name, Tab_Access__c, Role__c, Twilio_Admin_2FA__c, Twilio_Provider_2FA__c, Twilio_Patient_2FA__c FROM Portal_Access_Control__c
    const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
    return this.http.post(uri, data);
  }

  getPatientObject(patientName: string) {
    const data =
      environment.SF_GET_USER_OBJECT_ENDPOINT +
      'Patient__c/Name/' +
      patientName;
    const uri =
      environment.NODE_SERVICE_BASE_URL +
      environment.NODE_SERVICE_API_END_POINT +
      '/execute-query';

    return this.http.post(uri, data).subscribe((response: any) => {
      TaskService.task(response);
      let FullName = response.Last_Name__c + ',' + response.First_Name__c;
      sessionStorage.setItem('fullName', FullName);
      sessionStorage.setItem('pName', patientName);
      sessionStorage.setItem(
        'userData',
        CryptoService.encryptUsingAES256(JSON.stringify(response))
      );
      TaskService.task().loginAs(response.Patient_Name__c);
      this.titleSvc.setTitle(response.Patient_Name__c);

      this.patientData.next(response);
    });
  }

  getPatientImageData(patientName: string) {
    const request = {
      api:
        environment.CERBO_PATIENT_ENDPOINT +
        '/' +
        patientName +
        '/images?photo_type=personal',
      type: 'GET',
    };
    const uri =
      environment.NODE_SERVICE_BASE_URL +
      environment.NODE_SERVICE_API_END_POINT +
      '/post-data/cerbo';
    return this.http.post(uri, request);
    // .subscribe((response: any) => {
    //   console.log(response);
    //   if (response.data[0] != undefined) {
    //     const request1 = {
    //       api:
    //         environment.CERBO_PATIENT_ENDPOINT +
    //         '/' +
    //         patientName +
    //         '/images/' +
    //         response.data[0].id +
    //         '/content',
    //       type: 'GET',
    //     };
    //     const uri1 =
    //       environment.NODE_SERVICE_BASE_URL +
    //       environment.NODE_SERVICE_API_END_POINT +
    //       '/post-data/cerbo';
    //     return this.http.post(uri1, request1).subscribe((response: any) => {
    //       console.log(response);
    //       return this.sanitizer.bypassSecurityTrustUrl(
    //         'data:Image/jpeg;base64,' + response.base64_content
    //       );
    //     });
    //   }
    // });
  }

  getPatientImageBase64Content(patientName: string, id: string) {
    const request = {
      api:
        environment.CERBO_PATIENT_ENDPOINT +
        '/' +
        patientName +
        '/images/' +
        id +
        '/content',
      type: 'GET',
    };
    const uri =
      environment.NODE_SERVICE_BASE_URL +
      environment.NODE_SERVICE_API_END_POINT +
      '/post-data/cerbo';
    return this.http.post(uri, request);
  }
}
