<div id="main-wrapper" class="main-wrapper clearfix">
    <!-- ends in footer.php -->
  
  <div class="col-md-6 login_section">
    <div class="login-inner-container">
      <header id="header-login-wrapper" role="banner" class="header-login-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 intellectportal-site-logo">
              <a role="link" href=""><img role="img" alt="" src="../assets/images/Rose-Wellness-Logo.svg"></a>
            </div>
          </div>
        </div>

        <div *ngIf="isPortalRosewellness()">
          Please go to <a href={{newUrl}} target="_self" style="color: green;"><b>{{newUrl}}</b></a>
        </div>
      </header>
      <section id="content-wrapper" class="search-layout-wrapper clearfix">
        <!-- ends in footer.php -->
        <div class="login-wrapper formchnge ">
          <div class="container">
            <div class="row">
              <div class="col-xs-12">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="intellectportal_form intellectportal_login_form">
                  <mat-card class="mat-card mat-focus-indicator paddingTop50 paddingBottom50">
                  <fieldset>
                    <p>
                      <!-- <label for="intellectportal_username">Username</label>
                      <input type="text" id="intellectportal_username" name="intellectportal_username" class="user-input" formControlName="username"/> -->

                        <mat-form-field appearance="outline"floatlabel="always">
                        <mat-label for="intellectportal_username">Username</mat-label>
                        <input matInput type="text" id="intellectportal_username" name="intellectportal_username" class="user-input" formControlName="username">              
                        </mat-form-field>
                    </p>
                    <p>
                     <!--  <label for="intellectportal_user_password">Password</label>
                      <input type="password" id="intellectportal_user_password" name="intellectportal_user_password" class="user-input" formControlName="password"/> -->
                      <mat-form-field appearance="outline"floatlabel="always">
                        <mat-label for="intellectportal_user_password">Password</mat-label>
                        <input matInput type="password" id="intellectportal_user_password" name="intellectportal_user_password" class="user-input" formControlName="password">
                      
                    </mat-form-field>
                    </p>

                    <div class="login-as-wrapper">
                      <div><label for="login-as">Login as</label></div>
                    <div class="radioBtn">
                      <input name="loginAs" id="intellectportal_login_as_patient" type="radio"
                        value="patient" formControlName="loginAs">Patient
                      <input name="loginAs" id="intellectportal_login_as_provider" type="radio"
                        value="provider" formControlName="loginAs">Provider
                        <input name="loginAs" id="intellectportal_login_as_ecommerce" type="radio"
                        value="ecommerce" formControlName="loginAs">eCommerce
                      </div>

                    </div>
                    <div class="extra-login-inputs" *ngIf="!isPortalRosewellness()">
                      <p class="submit-container">
                        <!-- <input type="hidden" name="intellectportal_user_login_nonce" value="" />
                        <input type="hidden" name="intellectportal_refere" value="" /> -->
                        <button class="submit-btn floatNone" [class.spinner]="showprogress"  type="submit" name="submit" [disabled]="loginForm.invalid" mat-raised-button>Sign In </button>

                      </p>
                    </div>
                  </fieldset>
                  <div class="intellectportal-login-message-container" *ngIf ="hasError">
                    <p class="message-here">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error">Username/Password you entered is invalid.</span><br />
                    </p>
                  </div>
                  <div class="intellectportal-login-message-container" *ngIf ="hasErrorWithLoginVerify">
                    <p class="message-here">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error">Invalid Phone Number/Unable to Proceed.</span><br />
                    </p>
                  </div>
                  <div class="intellectportal-login-message-container" *ngIf ="hasErrorWithLoginPhoneNo">
                    <p class="message-here">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error">Phone No is Not Registered.</span><br />
                    </p>
                  </div>
                  <div class="intellectportal-login-message-container" *ngIf ="hasErrorWithLoginCount">
                    <p class="message-here">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error"> Too many concurrent verification code requests/ Exceeds verification code requests daily Limits</span><br />
                    </p>
                  </div>
                </mat-card>
                </form>
                <!-- <form [formGroup]="verifyForm" (ngSubmit)="onVerify()" *ngIf="showVerifyForm"  class="intellectportal_form intellectportal_login_form">
                  <mat-card class="mat-card mat-focus-indicator">
                  <fieldset>
                    <p style="text-align: right; margin-bottom:0px  !important;"><i class="fa fa-caret-square-o-left" style="font-size:24px; color: #6d6e71" (click)="reload()" title="Back"></i></p>
                      <p *ngIf="showVerifyForm">Please enter the verification code to sign in.</p>
                    <p>
                      <mat-form-field appearance="fill">
                        <mat-label for="intellectportal_username">Code</mat-label>
                        <input matInput type="text" id="intellectportal_username" name="intellectportal_username" class="user-input" formControlName="code">
                      
                    </mat-form-field>
                   
                    </p>

                    <div class="extra-login-inputs">
                      <p class="submit-container">
                        <button class="submit-btn" type="submit" name="submit" [disabled]="verifyForm.invalid" mat-raised-button>Verify</button>
                        <button type="button" class="btn btn-resend" (click)="resend()" title ="Resend Verification Code " mat-raised-button>Resend</button>
                      </p>
                    </div>
                  </fieldset>
                  <div class="intellectportal-login-message-container" *ngIf ="hasErrorWithVerify">
                    <p class="message-here">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error">Invalid Phone Number/Unable to Verify Phone.</span><br />
                    </p>
                  </div>
                  <div class="intellectportal-login-message-container" *ngIf ="hasErrorCode">
                    <p class="message-here">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error">Code you entered is invalid/Expired/Already Used.</span><br />
                    </p>
                  </div>
                  <div class="intellectportal-login-message-container" *ngIf ="hasErrorWithCount">
                    <p class="message-here">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error"> Max send checks / Exceeds verfication requests daily Limits</span><br />
                    </p>
                  </div>
                </mat-card>
                </form> -->
              </div>
            </div>
          </div>
        </div>
      </section><!-- #content-wrapper -->
      <footer id="login-footer-wrapper" class="login-footer-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 footer-login-links">
              <p><a target="_blank" href="#">Home</a></p>
              <p><a target="_blank" href="#">Contact Us</a></p>
            </div>
  
            <div class="col-xs-12">
              <!-- <?php
                    $footer_options = get_option('custom_theme_options');
                    $copyright_text = $footer_options['footer_copyright_text'];
                    ?> -->
          <!--     <p class="copyright-text">©2021 Rosewellness. All rights reserved.</p> -->
          <p>© {{getYear}} {{getCopyRights()}}</p>
            </div>
          </div>
        </div>
  
      </footer>
    </div>
  </div>

  <div class="col-md-6 image_section">   
      <img src="{{imagaePath}}{{selecteImage}}"/>
      <div class="datacontainer" #dataContainer></div>            
  </div>

  
  </div><!-- #main-wrapper -->