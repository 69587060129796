import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CryptoService } from 'src/app/shared/services/crytoservice/crypto-service.service';
import { TaskService } from 'src/app/shared/services/task.service';
import { LoginService } from 'src/app/core/services/login/login.service';

import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { range } from 'rxjs';
import { timer } from 'rxjs/observable/timer';
import "rxjs/add/operator/map";
import "rxjs/add/operator/zip";
import { UserRoles } from 'src/app/shared/constants/roles.constant';
@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.css']
})
export class PhoneVerificationComponent implements OnInit {
  user:any;
  userRole:any;
  userphone:any;
  maskedPhone:any;
  timerValue;
  source$:any;
  disposeMe:any;
  areTenSecsRemainings: boolean = false;
  
  verifyForm: UntypedFormGroup;
  loading = false;
  hasError = false;
  hasErrorWithVerify= false;
  hasErrorCode=false;
  hasErrorWithCount=false;
  constructor(  
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private loginSvc: LoginService,
    )  { }

  ngOnInit(): void {
    if(localStorage.user){
      this.userphone = CryptoService.decryptUsingAES256(localStorage.getItem('userphone'));
     this.maskedPhone = "XXXXXXX" + this.userphone.slice(this.userphone.length - 3);
      this.verifyForm = this.formBuilder.group({
        code: ['', Validators.required],
      });
    }
     else{
      this.router.navigate(['/']);
    }
    this.setTime();
  }
    // convenience getter for easy access to form fields
    // get f() { return this.verifyForm.controls; }

    onSubmit() {
      this.hasError = false;
      this.loading = true;
      // stop here if form is invalid
      if (this.verifyForm.invalid) {
        return;
      }
      if(!TaskService.task()?.userTask()?.mobilePhone()){
        this.router.navigate(['/']);
      } else{
      if(this.verifyForm.value.code.length === 6){
        this.userRole = CryptoService.decryptUsingAES256(localStorage.getItem('userole'));

         let phoneNumberstr = TaskService.task().userTask().mobilePhone();
         let phoneNumberRefined = phoneNumberstr.replace(/[^0-9]/g, '');
        let userInfo = {
          phonenumber: `1${phoneNumberRefined}`,
          code: this.verifyForm.value.code
        }
        this.loginSvc.verifyOtp(userInfo).subscribe((res) => {
          if (res) {
            if(res['to'] == `+${userInfo.phonenumber}` &&  res['status'] == "approved"){
              if(this.userRole == UserRoles.PROVIDER){
                this.router.navigate(['./adminDashboard']); 
              } else {
                this.router.navigate(['./patientDashboard']); 
              }
            } else if (res['status']== "pending" && res['valid']== false){
              this.hasErrorCode = true;
              this.loading = false;
            }
          } else {
            this.hasErrorWithVerify = true;
            this.loading = false;
          }
        },
        (err) => {
          if (err?.error?.err?.status == 429) {
                      this.hasErrorWithCount = true;
                      this.loading = false;
                    } else {
                      this.hasErrorWithVerify = true;
                      this.loading = false;
                    }
        });
      } else{
        this.hasErrorCode = true;
        this.loading = false;
      }
    }
    }
    
    resend(){
      this.disposeMe.unsubscribe();
      // disposeMe
      this.loading = true;
      this.hasError = false;
      this.hasErrorWithVerify= false;
      this.hasErrorCode=false;
      this.hasErrorWithCount=false;
      this.verifyForm.setValue({  
        code:"",  
        }); 
      if(!TaskService.task()?.userTask()?.mobilePhone()){
        this.router.navigate(['/']);
      } else{
      let userInfo = {
        phonenumber: this.userphone,
        channel: "sms"
      }
      this.loginSvc.getOtp(userInfo).subscribe((res) => {
        if (res) {
          if (res['to'] == `+${userInfo.phonenumber}` && res['status'] == "pending") {
            localStorage.setItem('userphone', CryptoService.encryptUsingAES256(userInfo.phonenumber));
            this.maskedPhone = "XXXXXXX" + userInfo.phonenumber.slice(userInfo.phonenumber.length - 3);
            let Msg = `Verification Code  has been sent to registred Phone: ${this.maskedPhone}`
            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-warning'
              },
              buttonsStyling: false
            })
  
            swalWithBootstrapButtons.fire({
              title: '',
              text: Msg,
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'OK',
              //cancelButtonText: 'CANCEL',
              reverseButtons: true,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            }).then((result) => {
              if (result.isConfirmed) {
                this.setTime();
              } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
              ) {
  
  
              }
            })
          }
        } else {
          this.hasErrorWithVerify = true;
          this.loading = false;
        }
      },
        (err) => {
          if (err?.error?.err?.status == 429) {
            this.hasErrorWithCount = true;
            this.loading = false;
          } else {
            this.hasErrorWithVerify = true;
            this.loading = false;
          }
        });
      }
    }
    setTime(){
      // if(this.source$ !== undefined){
      //   this.source$.unsubscribe();
      // }
    
      let value = 600;
      // this.source$.unsubscribe();
  
         this.source$ = range(1, value).zip(
          timer(0, 1000),
          (x) => { return x }
         ).map(x => {
          return value - x
         }); 

        this.disposeMe = this.source$.subscribe(seconds => {
        let second = seconds
        let mins = parseInt('' + seconds / 60);
        let secs = seconds % 60;
        let hrs = parseInt('' + mins / 60);
        mins = mins % 60;
        if (second < 60) this.areTenSecsRemainings = true;
        let res = {
          hours: hrs,
          minutes: mins,
          seconds: secs
        };
  
        this.timerValue = res;
        if (second == 0) {
          this.router.navigate(['/']);
        }
      });
    }
    reload(){
      this.router.navigate(['/']);
    }

}
