import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';

import { LoginService } from 'src/app/core/services/login/login.service';
import { UserRoles } from 'src/app/shared/constants/roles.constant';
import { GlobalSettingsService } from 'src/app/shared/services/global-settings/global-settings.service';
@Component({
  selector: 'app-authenication-popup',
  templateUrl: './authenication-popup.component.html',
  styleUrls: ['./authenication-popup.component.css']
})
export class AuthenicationPopupComponent implements OnInit {


  verifyForm: UntypedFormGroup;

  maskedPhone:any;

  areTenSecsRemainings: boolean = false;

  countDown: Subscription;
  counter = 600;
  tick = 1000;
  
  minie:any;
  secse:any;
  hasError = false;
  hasErrorWithVerify = false;
  hasErrorCode = false;
  hasErrorWithCount = false;

  hasErrorWithLoginVerify = false;
  hasErrorWithLoginCount = false;
  headerColor:any;
  okButton:any;
  cancelButton:any;

  constructor(
    public dialogRef: MatDialogRef<AuthenicationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private loginSvc: LoginService,private globalSettingSvc:GlobalSettingsService,
  ) { }

  ngOnInit(): void {
    this.headerColor =  this.globalSettingSvc.getpopupHeader();
    this.cancelButton = this.globalSettingSvc.getButtonCancel();
    this.okButton = this.globalSettingSvc.getButtonOk();

    this.maskedPhone = "***-***-**" + this.data?.phonenumber.slice(this.data?.phonenumber.length - 2);
    this.verifyForm = this.formBuilder.group({
      code: ['', Validators.required],
    });

    this.setTimer();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onClick() {
    // this.maskedPhone = "XXXXXXX" + this.userphone.slice(this.userphone.length - 3);
    if(this.verifyForm.value.code.length === 6){
      let userInfo = {
        phonenumber: this.data.phonenumber,
        code: this.verifyForm.value.code
      }
      this.loginSvc.verifyOtp(userInfo).subscribe((res) => {
        if (res) {
          if(res['to'] == `+${userInfo.phonenumber}` &&  res['status'] == "approved"){
            if(this.data.role == UserRoles.PROVIDER){
              this.router.navigate(['./adminDashboard']); 
              this.dialogRef.close();
            } else if(this.data.role == UserRoles.ECOMMERCE){
              this.router.navigate(['./adminDashboard']); 
              this.dialogRef.close();
            } 
            else {
              this.router.navigate(['./patientDashboard']); 
              this.dialogRef.close();
            }
          } else if (res['status']== "pending" && res['valid']== false){
            this.hasErrorCode = true;
            this.hasErrorWithVerify = false;
            this.hasErrorWithCount = false;
          }
        } else {
          this.hasErrorWithVerify = true;
          this.hasErrorCode = false;
          this.hasErrorWithCount = false;
        }
      },
      (err) => {
        if (err?.error?.err?.status == 429) {
                    this.hasErrorWithCount = true;
                    this.hasErrorWithVerify = false;
                    this.hasErrorCode = false;
                  } else {
                    this.hasErrorWithVerify = true;
                    this.hasErrorWithCount = false;
                    this.hasErrorCode = false;
                  }
      });
    } else{
      this.hasErrorCode = true;
      this.hasErrorWithVerify = false;
      this.hasErrorWithCount = false;
    }

  }

  resend() {
    let msg="A another one verification code has been sent ";
    let action="";

    this.hasErrorWithLoginVerify = false;
    this.hasErrorWithLoginCount = false;
    this.verifyForm.setValue({
      code: "",
    });

    let userInfo = {
      phonenumber: this.data.phonenumber,
      channel: "sms"
    }
    this.loginSvc.getOtp(userInfo).subscribe((res) => {
      if (res) {
        if (res['to'] == `+${userInfo.phonenumber}` && res['status'] == "pending") {
 
          this.countDown.unsubscribe();
          this.counter = 600;
          this.setTimer();
          this.openSnackBar(msg,action);
        }
      } else {
        this.hasErrorWithLoginVerify = true;
        this.hasErrorWithLoginCount = false;
      }
    },
      (err) => {
        if (err?.error?.err?.status == 429) {
          this.hasErrorWithLoginCount = true;
          this.hasErrorWithLoginVerify = false;
 
        } else {
          this.hasErrorWithLoginVerify = true;
          this.hasErrorWithLoginCount = false;
        }
      });
  }
  setTimer() {
    this.countDown = timer(0, this.tick).subscribe(() => {
      --this.counter;
      const minutes: number = Math.floor((this.counter % 3600) / 60);
      const secs = Math.floor(this.counter - minutes * 60);

      this.minie = ('00' + minutes).slice(-2);
      this.secse = ('00' + Math.floor(this.counter - minutes * 60)).slice(-2);
      if (this.counter < 60) this.areTenSecsRemainings = true;
      if (this.counter == 0) {
        this.countDown = null;
        this.dialogRef.close();
      }
    });
   
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
