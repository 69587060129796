interface ITab {
    name: string;
    class: string;
    link: string;
    routerLinkActive: string;
    subMenus: any;
}

export class Tab {
    private _name: string;
    private _class: string;
    private _link: string;
    private _routerLinkActive: string;
    private _subMenus: any;

    constructor({ ...options }: ITab) {
        this.set(options);
    }
    //Leads;Users;Access_Control;Missing_Forms;Transactions;Patients;Unsigned_Encounters;Open_Orders;Dashboard;Appointments;Error_Logs;Subscriptions;Support_Tickets;Patients#All_Patients;Patients#New_Patients
    set<T extends ITab>({ ...options }: T): void {
        this._name = options.name;
        this._class = options.class;
        this._link = options.link;
        this._routerLinkActive = options.routerLinkActive;
        this._subMenus = options.subMenus;
    }

    public cssClass() {
        return this._class;
    }

    public name() {
        return this._name;
    }

    public link() {
        return this._link;
    }

    public routerLinkActive() {
        return this._routerLinkActive;
    }

    public subMenus() {
        return this._subMenus ? this._subMenus : [];
    }
}
