import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CryptoService } from '../crytoservice/crypto-service.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  cerboToken = null;
  constructor(private router: Router) { }

  getToken(val?: string) {
    return val ? this.cerboToken = val : this.cerboToken;
  }

  openNewTab(uid, path, patientName?:any) {

    const id = CryptoService.encryptUsingAES256(uid);
    const queryParams:any = { id };
    if (patientName) {
      const name = CryptoService.encryptUsingAES256(patientName);
      queryParams.name = name;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree([path], { queryParams })
    );
    window.open(url, '_blank');
  }

  newTab(path, queryParams) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([path], { queryParams })
    );
    window.open(url, '_blank');
  }

  openNewTabProvider(uid, path, mnthYear?:any) {

    const id = CryptoService.encryptUsingAES256(uid);
    const queryParams:any = { id };
   
      const name = CryptoService.encryptUsingAES256(mnthYear);
      queryParams.monthName = name;
    
    const url = this.router.serializeUrl(
      this.router.createUrlTree([path], { queryParams })
      );
    window.open(url, '_blank');
  }
  openOnNewTab(data, path, patientName?: any) {
    
    const count = CryptoService.encryptUsingAES256(data.appointmentCount);
    const queryParams = {
      id:CryptoService.encryptUsingAES256(data.id),
      appointmentCount: CryptoService.encryptUsingAES256(data.appointmentCount)
    };

    const url = this.router.serializeUrl(
      this.router.createUrlTree([path], { queryParams })
    );
    window.open(url, '_blank');
  }

  reloadCurrentRoute() {
    //To refresh/call same route again to get latest data
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  openNewKBTab(uid, path, patientName?:any,topic?:string,question?:string) {

    const id = CryptoService.encryptUsingAES256(uid);
    const queryParams:any = { id };
    if (patientName && topic && question) {
      const name = CryptoService.encryptUsingAES256(patientName);
      topic = CryptoService.encryptUsingAES256(topic);
      question = CryptoService.encryptUsingAES256(question);
      queryParams.name = name;
      queryParams.topic = topic;
      queryParams.question = question;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree([path], { queryParams })
    );
    window.open(url, '_blank');
  }
}
