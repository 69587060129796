import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { ProfileService } from 'src/app/shared/services/profile/profile.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CerboService {
  constructor(private http: HttpClient, private profileSvc: ProfileService) { }

  public authoriseUser(data:any) {
    const uri = environment.NODE_SERVICE_BASE_URL+environment.NODE_SERVICE_API_END_POINT+'/cerbo-auth';
    const postData = {
      loginDetails: data.loginDetails,
      loginAs: data.loginAs
    };
    return this.http.post(uri, postData);
   }
}
