import { Pipe, PipeTransform } from '@angular/core';
import { TaskService } from '../services/task.service';

@Pipe({
  name: 'activePatients',
  pure: true
})
export class MissingFormDataTypesPipe implements PipeTransform {

  //arg1: active/inactive
  //arg2: count
  transform(value: Array<any>, arg1?: string, arg2: boolean = false) {
    let result = [];
    const isMA = TaskService.task().userTask().isMA();

    switch (arg1) {
      case 'active': {
        if (isMA) {
          result = value.filter(x => {
            if (x.nextAppointmentType) {
              const appType = x.nextAppointmentType.toLowerCase();
              const hasNewPatient = appType.includes('new patient');
              return x.lastAppointmentDate && hasNewPatient;
            }
            return false;
          });
        } else {
          result = value.filter(x => x.lastAppointmentDate);
        }

        break;
      }

      case 'inactive': {
        if (isMA) {
          result = value.filter(x => {
            if (x.lastAppointmentType) {
              const appType = x.lastAppointmentType.toLowerCase();
              const hasNewPatient = appType.includes('new patient');
              return !x.lastAppointmentDate && hasNewPatient;
            }
            return false;
          });
        } else {
          result = value.filter(x => !x.lastAppointmentDate);
        }
        break;
      }

      case 'health': {
        result = value.filter(x => x.nexthealthCoachingApptDate);
        break;
      }
    }

    return arg2 ? result.length : result;
  }
}
