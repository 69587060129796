<div id="main-wrapper" class="main-wrapper clearfix">
    <!-- ends in footer.php -->
  
    <div class="login-inner-container">
      <header id="header-login-wrapper" role="banner" class="header-login-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 intellectportal-site-logo">
              <a role="link" href=""><img role="img" alt="" src="../assets/images/Rose-Wellness-Logo.svg"></a>
            </div>
          </div>
        </div>
      </header>
      <section id="content-wrapper" class="search-layout-wrapper clearfix">
        <!-- ends in footer.php -->
        <div class="login-wrapper">
          <div class="container formchnge">
            <div class="row">
              <div class="col-xs-12">
                <form [formGroup]="verifyForm" (ngSubmit)="onSubmit()"   class="intellectportal_form intellectportal_login_form">
                  <mat-card class="mat-card mat-focus-indicator paddingTop50">
                  <fieldset>
                    <p style="text-align: right; margin-bottom:0px  !important;"><i class="fa fa-caret-square-o-left" style="font-size:24px; color: #6d6e71" (click)="reload()" title="Back to Login"></i></p>
                      <p style="margin:10px 0px ">Please enter the verification code to sign in.</p>
                    <p>
                      <!-- <label for="intellectportal_username"> Code</label>
                      <input type="text" id="intellectportal_username" name="intellectportal_username" class="user-input" formControlName="code"/> -->
                      <mat-form-field appearance="outline"floatLabel="always">
                        <mat-label for="intellectportal_username">Code</mat-label>
                        <input matInput type="text" id="intellectportal_username" name="intellectportal_username" class="user-input" formControlName="code">
                      
                    </mat-form-field>
                   
                    </p>

                    <div class="extra-login-inputs">
                      <p class="submit-container">
                        <button class="submit-btn" type="submit" name="submit" [disabled]="verifyForm.invalid" mat-raised-button>Verify and Proceed</button>
                        <!-- <button type="button" class="btn btn-resend" (click)="resend()" title ="Resend Verification Code " mat-raised-button>Resend</button> -->
                      </p>
                    </div>
                    <p class="resend">
                      <span class="resend-span">Didn't received the verfication code? <a (click)="resend()" class="send-a">Click here to send another one.</a> </span>
                    </p>
                    <p class="time"[style.color]="areTenSecsRemainings ? 'red' : ''" >
                      <span>
                     {{
                        timerValue?.minutes | number: '2.0'
                      }}m </span> :<span>{{ timerValue?.seconds | number: '2.0' }}s
                    </span>
                    </p>
                  </fieldset>
                  <div class="intellectportal-login-message-container" *ngIf ="hasErrorWithVerify">
                    <p class="message-here">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error">Invalid Phone Number/Unable to Verify Phone.</span><br />
                    </p>
                  </div>
                  <div class="intellectportal-login-message-container" *ngIf ="hasErrorCode">
                    <p class="message-here">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error">Code you entered is invalid/Expired/Already Used.</span><br />
                    </p>
                  </div>
                  <div class="intellectportal-login-message-container" *ngIf ="hasErrorWithCount">
                    <p class="message-here">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error"> Max send checks / Exceeds verfication requests daily Limits</span><br />
                    </p>
                  </div>
                </mat-card>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section><!-- #content-wrapper -->
      <footer id="login-footer-wrapper" class="login-footer-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 footer-login-links">
              <p><a target="_blank" href="#">Home</a></p>
              <p><a target="_blank" href="#">Contact Us</a></p>
            </div>
  
            <div class="col-xs-12">
              <!-- <?php
                    $footer_options = get_option('custom_theme_options');
                    $copyright_text = $footer_options['footer_copyright_text'];
                    ?> -->
              <p class="copyright-text">©2021 Rosewellness. All rights reserved.</p>
            </div>
          </div>
        </div>
  
      </footer>
    </div>
  
  </div><!-- #main-wrapper -->