import { UserRoles } from "../constants/roles.constant";

interface IProvider {
    Id: string;
    ID__c: string;
    CreatedById: string;
    CreatedDate: string;
    DOB__c: string;
    Date_Added__c: string;
    Display_On_Reports__c: boolean;
    Email__c: string;
    First_Name__c: string;
    Called_By_Name__c: string;
    IsDeleted: boolean;
    LastActivityDate: string;
    LastModifiedById: string;
    LastModifiedDate: string;
    Last_Name__c: string;
    Middle_Name__c: string;
    Mobile_Phone__c: string;
    NPI__c: string;
    Name: string;
    Name__c: string;
    OwnerId: string;
    Prefix__c: string;
    Provider_Type__c: string;
    Provider__c: boolean;
    Staff__c: string;
    Status__c: string;
    Subscription_Owner__c: boolean;
    Suffix__c: string;
    SystemModstamp: string;
    Task_Creation__c: boolean;
    UserName__c: string;
    TimeZone__c:string;
    MSO__c:string;
    Company__c:string;
    EMR_Bypass__c:string;
    Ticket_Access__c:string;

}
export class Provider {
    private Id: string;
    private ID__c: string;
    private CreatedById: string;
    private CreatedDate: string;
    private DOB__c: string;
    private Date_Added__c: string;
    private Display_On_Reports__c: boolean;
    private Email__c: string;
    private First_Name__c: string;
    private Called_By_Name__c: string;
    private IsDeleted: boolean;
    private LastActivityDate: string;
    private LastModifiedById: string;
    private LastModifiedDate: string;
    private Last_Name__c: string;
    private Middle_Name__c: string;
    private Mobile_Phone__c: string;
    private NPI__c: string;
    private Name: string;
    private Name__c: string;
    private OwnerId: string;
    private Prefix__c: string;
    private Provider_Type__c: string;
    private Provider__c: boolean;
    private Staff__c: string;
    private Status__c: string;
    private Subscription_Owner__c: boolean;
    private Suffix__c: string;
    private SystemModstamp: string;
    private Task_Creation__c: boolean;
    private UserName__c: string;
    private TimeZone__c:string;
    private MSO__c:string;
    private Company__c:string;
    private EMR_Bypass__c:string;
    private Ticket_Access__c:string;

    constructor({ ...options }: IProvider) {
        this.set(options);
    }

    set<T extends IProvider>({ ...options }: T): void {
        this.CreatedById= options.CreatedById;
        this.CreatedDate= options.CreatedDate;
        this.DOB__c= options.DOB__c;
        this.Date_Added__c= options.Date_Added__c;
        this.Display_On_Reports__c= options.Display_On_Reports__c;
        this.Email__c= options.Email__c;
        this.First_Name__c= options.First_Name__c;
        this.Called_By_Name__c = options.Called_By_Name__c;
        this.ID__c= options.ID__c;
        this.Id= options.Id;
        this.IsDeleted= options.IsDeleted;
        this.LastActivityDate= options.LastActivityDate;
        this.LastModifiedById= options.LastModifiedById;
        this.LastModifiedDate= options.LastModifiedDate;
        this.Last_Name__c= options.Last_Name__c;
        this.Middle_Name__c= options.Middle_Name__c;
        this.Mobile_Phone__c= options.Mobile_Phone__c;
        this.NPI__c= options.NPI__c;
        this.Name= options.Name;
        this.Name__c= options.Name__c;
        this.OwnerId= options.OwnerId;
        this.Prefix__c= options.Prefix__c;
        this.Provider_Type__c= options.Provider_Type__c;
        this.Provider__c= options.Provider__c;
        this.Staff__c= options.Staff__c;
        this.Status__c= options.Status__c;
        this.Subscription_Owner__c= options.Subscription_Owner__c;
        this.Suffix__c= options.Suffix__c;
        this.SystemModstamp= options.SystemModstamp;
        this.Task_Creation__c= options.Task_Creation__c;
        this.UserName__c= options.UserName__c;
        this.TimeZone__c= options.TimeZone__c;
        this.MSO__c = options.MSO__c;
        this.Company__c = options.Company__c;
        this.EMR_Bypass__c = options.EMR_Bypass__c;
        this.Ticket_Access__c = options.Ticket_Access__c;
    }

    /*
        All getter an setters
    */
    public id_c(val: string) {
        return val ? this.ID__c = val : this.ID__c;
    }

    public id(val: string) {
        return val ? this.Id = val : this.Id;
    }

    public createdById(val: string) {
        return val ? this.CreatedById = val : this.CreatedById;
    }

    public createdDate(val: string) {
        return val ? this.CreatedDate = val : this.CreatedDate;
    }

    public dob(val: string) {
        return val ? this.DOB__c = val : this.DOB__c;
    }

    public dateAdded(val: string) {
        return val ? this.Date_Added__c = val : this.Date_Added__c;
    }

    public displayOnReports(val: boolean) {
        return val ? this.Display_On_Reports__c = val : this.Display_On_Reports__c;
    }

    public email(val: string) {
        return val ? this.Email__c = val : this.Email__c;
    }

    public firstName(val: string) {
        return val ? this.First_Name__c = val : this.First_Name__c;
    }

    public calledByName(val: string) {
        return val ? this.Called_By_Name__c = val : this.Called_By_Name__c;
    }

    public isDeleted(val: boolean) {
        return val ? this.IsDeleted = val : this.IsDeleted;
    }

    public lastActivityDate(val: string) {
        return val ? this.LastActivityDate = val : this.LastActivityDate;
    }

    public lastModifiedById(val: string) {
        return val ? this.LastModifiedById = val : this.LastModifiedById;
    }

    public lastModifiedDate(val: string) {
        return val ? this.LastModifiedDate = val : this.LastModifiedDate;
    }

    public lastName(val: string) {
        return val ? this.Last_Name__c = val : this.Last_Name__c;
    }

    public middleName(val: string) {
        return val ? this.Middle_Name__c = val : this.Middle_Name__c;
    }

    public mobilePhone(val: string) {
        return val ? this.Mobile_Phone__c = val : this.Mobile_Phone__c;
    }

    public npi(val: string) {
        return val ? this.NPI__c = val : this.NPI__c;
    }

    public name(val: string) {
        return val ? this.Name = val : this.Name;
    }

    public name_c(val: string) {
        return val ? this.Name__c = val : this.Name__c;
    }

    public ownerId(val: string) {
        return val ? this.OwnerId = val : this.OwnerId;
    }

    public prefix(val: string) {
        return val ? this.Prefix__c = val : this.Prefix__c;
    }

    public providerType(val: string) {
        return val ? this.Provider_Type__c = val : this.Provider_Type__c;
    }

    public provider(val: boolean) {
        return val ? this.Provider__c = val : this.Provider__c;
    }

    public staff(val: string) {
        return val ? this.Staff__c = val : this.Staff__c;
    }

    public status(val: string) {
        return val ? this.Status__c = val : this.Status__c;
    }

    public subscriptionOwner(val: boolean) {
        return val ? this.Subscription_Owner__c = val : this.Subscription_Owner__c;
    }

    public suffix(val: string) {
        return val ? this.Suffix__c = val : this.Suffix__c;
    }

    public systemModstamp(val: string) {
        return val ? this.SystemModstamp = val : this.SystemModstamp;
    }

    public taskCreation(val: boolean) {
        return val ? this.Task_Creation__c = val : this.Task_Creation__c;
    }

    public userName(val: string) {
        return val ? this.UserName__c = val : this.UserName__c;
    }

    public timeZone(val: string) {
        return val ? this.TimeZone__c = val : this.TimeZone__c;
    }

    public isMSO() {
        return this.MSO__c === 'Yes';
    }

    public companyId() {
        return this.Company__c;
    }

    public isMA() {
        return this.Staff__c === UserRoles.MA;
    }

    public isAdmin() {
        return this.Staff__c === UserRoles.ADMIN;
    }

    public isProvider() {
        return this.Staff__c === UserRoles.PROVIDER;
    }

    public isMD() {
        return this.Staff__c === UserRoles.MD;
    }

    public isSubscriptionOwner() {
        return this.Subscription_Owner__c;
    }
    public emrBypass(val: string) {
        return val ? this.EMR_Bypass__c = val : this.EMR_Bypass__c;
    }
    public ticketAccess(val: string) {
        return val ? this.Ticket_Access__c = val : this.Ticket_Access__c;
    }

    /*
        Returns all provider properties in json format
    */
    get(): IProvider {
        const data = {
            Id: this.Id,
            ID__c: this.ID__c,
            CreatedById: this.CreatedById,
            CreatedDate: this.CreatedDate,
            DOB__c: this.DOB__c,
            Date_Added__c: this.Date_Added__c,
            Display_On_Reports__c: this.Display_On_Reports__c,
            Email__c: this.Email__c,
            First_Name__c: this.First_Name__c,
            Called_By_Name__c: this.Called_By_Name__c,
            IsDeleted: this.IsDeleted,
            LastActivityDate: this.LastActivityDate,
            LastModifiedById: this.LastModifiedById,
            LastModifiedDate: this.LastModifiedDate,
            Last_Name__c: this.Last_Name__c,
            Middle_Name__c: this.Middle_Name__c,
            Mobile_Phone__c: this.Mobile_Phone__c,
            NPI__c: this.NPI__c,
            Name: this.Name,
            Name__c: this.Name__c,
            OwnerId: this.OwnerId,
            Prefix__c: this.Prefix__c,
            Provider_Type__c: this.Provider_Type__c,
            Provider__c: this.Provider__c,
            Staff__c: this.Staff__c,
            Status__c: this.Status__c,
            Subscription_Owner__c: this.Subscription_Owner__c,
            Suffix__c: this.Suffix__c,
            SystemModstamp: this.SystemModstamp,
            Task_Creation__c: this.Task_Creation__c,
            UserName__c: this.UserName__c,
            TimeZone__c: this.TimeZone__c,
            MSO__c: this.MSO__c,
            Company__c: this.Company__c,
            Ticket_Access__c: this.Ticket_Access__c,
            EMR_Bypass__c: this.EMR_Bypass__c,
        }
        return data;
      }
}
/* ------SAMPLE DATA ------
{
    "Id": "a0Q1M00000YoWXFUA3",
    "OwnerId": "005410000015KMKAA2",
    "IsDeleted": false,
    "Name": "51",
    "CreatedDate": "2021-09-17T15:55:59.000+0000",
    "CreatedById": "005410000015KMKAA2",
    "LastModifiedDate": "2023-07-02T05:51:07.000+0000",
    "LastModifiedById": "005410000015KMKAA2",
    "SystemModstamp": "2023-07-02T05:51:07.000+0000",
    "LastActivityDate": "2023-01-19",
    "LastViewedDate": "2023-08-03T04:20:41.000+0000",
    "LastReferencedDate": "2023-08-03T04:20:41.000+0000",
    "Date_Added__c": null,
    "First_Name__c": "Andrew",
    "Last_Name__c": "Shiller",
    "UserName__c": "drshiller",
    "Provider__c": false,
    "Status__c": "Active",
    "Name__c": "Andrew Shiller",
    "Middle_Name__c": null,
    "Email__c": "51@yopmail.com",
    "Prefix__c": null,
    "Suffix__c": null,
    "NPI__c": null,
    "DOB__c": null,
    "Display_On_Reports__c": false,
    "Provider_Type__c": "Physician",
    "Staff__c": "MSO Provider",
    "Mobile_Phone__c": "78788787",
    "Subscription_Owner__c": true,
    "Task_Creation__c": true,
    "Secondary_Role__c": null,
    "ID__c": "51",
    "TeleHealth_Only__c": true,
    "TimeZone__c": "(GMT+03:00) Israel Daylight Time (Asia/Jerusalem)",
    "Notes__c": null,
    "Schedule__c": "Tuesday, Wednesday: 8.45 am - 12.45 pm",
    "Auto_Zoom_Enabled__c": true,
    "Zoom_Account_Id__c": "80h46w_2TfWRpAibPfOumQ",
    "Color__c": "#F7EF07",
    "Company__c": "a1c790000008bkaAAA",
    "Opt_Out_of_Medicare_Expiry_Date__c": null,
    "Opt_Out_of_Medicare__c": null,
    "Password__c": null,
    "MSO__c": "Yes",
    "Called_By_Name__c": "Dr Shiller",
    "Unsigned_Encounters__c": 3,
    "Send_Encounter_Email__c": false,
    "Last_HIPAA_Training__c": null,
    "HIPAA_Privacy__c": false,
    "HIPAA_Security__c": false,
    "OSHA_Training__c": false,
    "Other_Training__c": false,
    "Missing_Orders__c": null,
    "Missing_Order_Patients__c": null,
    "Appointment_Types__c": null
} */