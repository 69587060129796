import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ProfileService } from 'src/app/shared/services/profile/profile.service';
import { environment } from 'src/environments/environment';
import { TaskService } from 'src/app/shared/services/task.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserDetailsService {

	constructor(private http: HttpClient, private profileSvc: ProfileService) { }

	getUserDetails() {
		// let accessToken = '00D010000008lq5!ARYAQFHY.az_gb6WvYSZ2NDlEVfO.xuQqKplz.92jyL6suTGh5uE0M5sgHm8czi068wNIp08Hul8c3yXWwsu4E6q.Et8GH_D';
		//let endPoint = "https://rosewellness--stage.my.salesforce.com/services/data/v52.0/sobjects/OfficeUser__c/Email__c/sanjay@rosewellness.com";
		//this.profileSvc.getToken('Bearer ' + accessToken);

		//return this.http.get(endPoint);
	}
	getProvidersDataByMonth(id) {
		if (id == null) {
			id = TaskService.task().userTask().id();
		}
		let query;		

		query =`SELECT Id, Appointment_Start__c, Appointment_End__c, Status__c, Duration_Hours__c, 
		  Duration__c, Name, AppointmentType__r.Name, Provider_Name__c, ProviderName__c FROM Appointment__c
          WHERE Provider_Name__c =  '${id}' 
          AND Status__c != 'Cancelled' AND  
          Patient__c != null AND Patient__r.Test_Patient__c=false AND AppointmentType__r.Name != null AND
          AppointmentType__r.Name NOT IN ('OUT OF OFFICE','Portal Time','Misc.','Vacation')
          AND Appointment_Start__c >= LAST_N_MONTHS:12 and Appointment_Start__c <= NEXT_N_MONTHS:2
		  order by Appointment_Start__c asc`;
		const data = environment.SF_QUERY + query;
		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
		return this.http.post(uri, data);

	}
	getProvidersDataByLastTenMonth(id) {
		if (id == null) {
			id = TaskService.task().userTask().id();
		}
		let query;
		//query =`SELECT Patient_ID__c, Patient_ID_Text__c, Patient_First_Name__c, Patient_Last_Name__c, Next_Appointment_Date__c, Last_Appointment_Date__c FROM Patient_Permissions__c WHERE Provider_ID__c = '${pId}' AND Patient_ID__r.Status__c ='Active' `;
		query = `SELECT Id, Appointment_Start__c, Appointment_End__c, Status__c, Duration_Hours__c, Duration__c, Name, AppointmentType__r.Name, Provider_Name__c, ProviderName__c FROM Appointment__c  WHERE Provider_Name__c = '${id}' AND AppointmentType__r.Name like '%25New%25'  AND  Patient__c !=null AND Patient__r.Status__c ='Active' AND Status__c NOT IN ('Cancelled') AND AppointmentType__r.Name NOT IN ('OUT OF OFFICE','Portal Time','Misc.') AND Appointment_Start__c >= LAST_N_MONTHS:12  order by Appointment_Start__c asc`
		const data = environment.SF_QUERY + query;
		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
		return this.http.post(uri, data);

		// Next_Appointment_Type__r.Name like '%25New%25'
	}
	getProvidersSubscriptionData(id?) {
		if (id == null) {
			id = TaskService.task().userTask().id();
		}
		let query;

		//query =`SELECT Patient_ID__c, Patient_ID_Text__c, Patient_First_Name__c, Patient_Last_Name__c, Next_Appointment_Date__c, Last_Appointment_Date__c FROM Patient_Permissions__c WHERE Provider_ID__c = '${pId}' AND Patient_ID__r.Status__c ='Active' `;
		//query = `SELECT Id, Appointment_Start__c, Appointment_End__c, Status__c, Duration_Hours__c, Duration__c, Name, AppointmentType__r.Name, Provider_Name__c, ProviderName__c FROM Appointment__c  WHERE Provider_Name__c = '${id}' AND  Patient__c !=null AND Patient__r.Status__c ='Active' AND Status__c NOT IN ('Cancelled','Rescheduled') AND AppointmentType__r.Name NOT IN ('OUT OF OFFICE','Portal Time','Misc.') AND Appointment_Start__c >= LAST_N_MONTHS:10  order by Appointment_Start__c asc`
		query = `SELECT Id,Name,Subscription_Start_Date__c,Subscription_Owner__c,Subscription_Plan__c,Owner__c,Patient_ID__c,Patient_ID__r.Patient_Name__c,Patient_ID__r.Email__c FROM Subscription__c where Owner__c = '${id}' AND Subscription_Plan__c !=null AND Is_Active__c=true `;
		const data = environment.SF_QUERY + query;
		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
		return this.http.post(uri, data);

	}

	getPaitentbyCount(name) {
		let id = TaskService.task().userTask().id();
		let date = new Date();
        let firstDayOne = date.setMonth(date.getMonth() - 36);
        let lastDayOne = new Date();
		let parsedStartDate = new Date(firstDayOne).toJSON();
        let parsedEndDate = new Date(lastDayOne).toJSON();

		let startDate = parsedStartDate.replace('Z', '') + "" + "+0000";
		let endDate = parsedEndDate.replace('Z', '') + "" + "+0000";

		const data = {
			"SummaryType": "Summary",
			"FromDate": startDate,
			"ToDate": endDate,
			"Provider": id
		};


		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/post-data';

		const postData = {
			api: '/services/apexrest/PatientVisits',
			data,
			type: 'POST'
		}
		return this.http.post(uri, postData);
	}

	private dataSource = new BehaviorSubject('');
	getData = this.dataSource.asObservable();


	changeData(data: any) {
		this.dataSource.next(data)
	}

	getChargeMadeIncorreectAcc(id?) {
		let query;

		
		query = `SELECT Id, Name, Incorrect_Charge__c,Transaction_Date__c,Transaction_Amount_Charged__c, Patient__c, Patient__r.Name, Patient__r.Patient_Name__c, Patient__r.Primary_Provider_Id__r.Company__r.Name, Company__r.Name,Company__r.Company_Abbreviation__c
		FROM Transaction__c where Transaction_Date__c >= LAST_N_MONTHS:12 AND Company__c != NULL and Patient__r.Primary_Provider_Id__c != NULL and Incorrect_Charge__c = true
		`;
		const data = environment.SF_QUERY + query;
		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
		return this.http.post(uri, data);

	}
	getleadMetricsdata(createdDateCondition){
		let query = `SELECT Id, Name, Email, Phone, MobilePhone, Status, Patient_Status__c, LeadSource, 
						Residency_State__c, Interests__c, CreatedDate,Contact_Date__c
					FROM Lead WHERE CreatedDate >= ${createdDateCondition} order by CreatedDate desc`;
		const data = environment.SF_QUERY + query;
		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
		return this.http.post(uri, data);
	}
	getInteractionMetrics(createdDateCondition){			
		let query = `SELECT Id, Name, Status__c, Type__c, State__c, Interests__c, CreatedDate, Contact_Date__c
		FROM Interaction__c	WHERE CreatedDate >= ${createdDateCondition} order by CreatedDate desc`;
		const data = environment.SF_QUERY + query;
		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
		return this.http.post(uri, data);
	}
	
	getNextList(url) {
		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
		return this.http.post(uri, url);
	  }

	  getLicendState(){
		let query;		
		query = `SELECT Id, Licensed_Service_States__c,Licensed_States_Abbreviation__c FROM Spam_Lead_Configuration__mdt WHERE DeveloperName='Licensed_States'	
		`;
		const data = environment.SF_QUERY + query;
		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
		return this.http.post(uri, data);
	  }

	getNoShowAppts(){
		const query = `SELECT
							Id,
							Name,
							Patient_Name__c,
							(
							SELECT
								Id,
								Appointment_Start__c,
								Appointment_End__c,
								Status__c,
								ProviderName__c,
								Appointment_Type_Name__c,
								Billed__c,
								Billing_Notes__c,
								Billing_Waiver__c 
							FROM
								Appointments__r 
							WHERE
								Status__c = 'No-Show' 
								and Billed__c = false 
								and Appointment_Start__c >= LAST_N_DAYS:180
							order by
								Appointment_Start__c desc
							),
							(
							SELECT
								Id,
								Transaction_Date__c,
								Transaction_Amount_Charged__c
							FROM
								Transactions__r 
							order by
								Transaction_Date__c desc limit 3
							)
						FROM
							Patient__c 
						WHERE
							Status__c = 'Active' 
							and Test_Patient__c = false 
							and Id IN 
							(
							Select
								Patient__c 
							from
								Appointment__c 
							WHERE
								Status__c = 'No-Show' 
								and Billed__c = false 
								and Appointment_Start__c >= LAST_N_DAYS:180
							)`;
		const data = environment.SF_QUERY + query;
		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
		return this.http.post(uri, data);
	}

	saveBilledData(id) {
		const data = {
			Billed__c: true
		};

		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/post-data';

		const postData = {
			api: '/services/data/v56.0/sobjects/Appointment__c/' + id,
			data,
			type: 'PATCH'
		}
		return this.http.post(uri, postData);
	}

	saveAppointmentData(id, appointmentData:any){
		const data = {
			Billing_Waiver__c: appointmentData.Billing_Waiver__c ? appointmentData.Billing_Waiver__c : false,
			Billed__c: appointmentData.Billed__c ? appointmentData.Billed__c : false,
			Billing_Notes__c: appointmentData.Billing_Notes__c,
		};

		const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/post-data';

		const postData = {
			api: '/services/data/v56.0/sobjects/Appointment__c/' + id,
			data,
			type: 'PATCH'
		}
		return this.http.post(uri, postData);
	}
}
