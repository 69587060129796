interface IGlobalSettings {
    Colors__c:string;
    Id:string;
    Name:string;
    Twilio_Attempts__c:number;
    User_Timeout__c:number;
    Copyright__c: string
}

export class GlobalSettings {
    private _colors:string;
    private _id:string;
    private _name:string;
    private _twilioAttempts:number;
    private _userTimeout:number;
    private _copyright:string;

    constructor({ ...options }: IGlobalSettings) {
        this.set(options);
    }

    set<T extends IGlobalSettings>({ ...options }: T): void {
        this._id = options.Id;
        this._name = options.Name;
        this._colors = options.Colors__c;
        this._twilioAttempts = options.Twilio_Attempts__c;
        this._userTimeout = options.User_Timeout__c;
        this._copyright = options.Copyright__c;
    }

    get color() {
        return this._colors;
    }

    get twilioAttempts() {
        return this._twilioAttempts;
    }

    get userTimeout() {
        return this._userTimeout;
    }

    get name() {
        return this._name;
    }

    get copyright() {
        return this._copyright;
    }
}