import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
//import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

/*import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';*/

//There are many components, directives & pipes, which we may like to share across various modules
//The commonly required angular modules like ( CommonModule, FormsModule, etc) or third party modules can be imported here and re-exported

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProviderTransactionsComponent } from './components/provider-transactions/provider-transactions.component';
import { LeadsComponent } from './components/leads/leads.component';
import { NewLeadComponent } from './components/leads/new-lead/new-lead.component';
import { AlertComponent } from './components/alerts/alert/alert.component';
import { AppoinmentsComponent } from './components/appoinments/appoinments.component';
import { ProviderAccessControlComponent } from './components/provider-access-control/provider-access-control.component';
import { MissingFormsComponent } from './components/missingforms/missingforms/missingforms.component';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { ReplaceStringPipe } from './pipes/replace-string.pipe';
import { ShortArrayPipe } from './pipes/short-array.pipe';
import { MissingFormDataTypesPipe } from './pipes/missing-form-data-types.pipe';
import { Ng2CompleterModule } from "ng2-completer";
import { TabsPipe } from './pipes/tabs.pipe';
import { OpenOrderComponent } from './components/open-order/open-order.component';
import { RowsCountPipe } from './pipes/rows-count.pipe';
import { UnsignedEncountersComponent } from './components/unsigned-encounters/unsigned-encounters.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SortdatePipe } from './pipes/sortdate.pipe';
import { SumPipe } from './pipes/sum.pipe';
//import { SortnamePipe } from './pipes/sortname.pipe';
import { ErrorLogsComponent } from './components/error-log-pages/error-logs/error-logs.component';
import { AppointPerHourComponent } from './components/bars/appoint-per-hour/appoint-per-hour.component';
import { AppointRecordCountComponent } from './components/bars/appoint-record-count/appoint-record-count.component';
import { AppointRecordCountHorizonComponent } from './components/bars/appoint-record-count-horizon/appoint-record-count-horizon.component';
import { SubscriptionCountComponent } from './components/bars/subscription-count/subscription-count.component';
import { CountPerSubscriptionComponent } from './components/table/count-per-subscription/count-per-subscription.component';
import { PatientCountComponent } from './components/table/patient-count/patient-count.component';
import { SupportTicketComponent } from './components/support-ticket/support-ticket.component';
import { NewticketComponent } from './components/newticket/newticket.component';
import { UpdateticketComponent } from './components/updateticket/updateticket/updateticket.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SubFilterPipe } from './pipes/sub-filter.pipe';
import { SortObjectPipe } from './pipes/sort-object.pipe';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { PatientNoteComponent } from './components/patient-note/patient-note.component';
import { PastappoinmentsComponent } from './components/pastappoinments/pastappoinments.component';
import { ScheduleChangeUpdateComponent } from './components/schedule-change-update/schedule-change-update.component';
import { TimeformatPipe } from './pipes/timeformat.pipe';
import { IdeasComponent } from './components/ideas/ideas.component';
import { AdminProviderComponent } from './components/admin-provider/admin-provider.component';
import { ProviderUpdateComponent } from './components/provider-update/provider-update.component';
import { MarketingEventsComponent } from './components/marketing-events/marketing-events.component';
import { NewMarketEventComponent } from './components/new-market-event/new-market-event.component';
import { UpdateMarketEventComponent } from './components/update-market-event/update-market-event.component';
import { LifeEventComponent } from './components/life-event/life-event.component';
import { NewLifeEventComponent } from './components/new-life-event/new-life-event.component';
import { UpdateLifeEventComponent } from './components/update-life-event/update-life-event.component';
import { Date4Pipe } from './pipes/date4.pipe';
import { EmployeeProfileComponent } from './components/employee-profile/employee-profile.component';
import { ReplaceLineBreaksPipe } from './pipes/replace-line-breaks.pipe';
import { StackedBarChartComponent } from './components/stacked-bar-chart/stacked-bar-chart.component';
import { ReplaceLastdigitPipe } from './pipes/replace-lastdigit.pipe';
import { ReleasePlanComponent } from './components/release-plan/release-plan.component';
import { NewReleasePlanComponent } from './components/release-plan/new-release-plan/new-release-plan.component';
import { UpdateReleaseComponent } from './components/release-plan/update-release/update-release.component';
import { PatientAlertComponent } from './components/patient-alert/patient-alert.component';
import { ConfirmBoxComponent } from './components/confirm-box/confirm-box.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { QuillModule } from 'ngx-quill';
import { IncompleteAddComponent } from './components/patient-alert/incomplete-add/incomplete-add.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { SupplementsComponent } from './components/supplements/stock-supplements/supplements.component';
import { LabKitsComponent } from './components/labs/lab-kits/lab-kits.component';
import { PatientLabsComponent } from './components/labs/patient-labs/patient-labs.component';
import { SupplememntsInsightsComponent } from './components/supplements/supplememnts-insights/supplememnts-insights.component';

import { ActivePatientsComponent } from './components/supplements/active-patients/active-patients.component';
import { InActivePatientsComponent } from './components/supplements/in-active-patients/in-active-patients.component';
import { StateCodeMappingPipe } from './pipes/state-code-mapping.pipe';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReplacenPipe } from './pipes/replacen.pipe';
import { InvoicesComponent } from './components/provider-transactions/invoices/invoices.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SubscriptionAlertComponent } from './components/notification-alerts/subscription-alert/subscription-alert.component';
import { LeadConsultationAlertComponent } from './components/notification-alerts/lead-consultation-alert/lead-consultation-alert.component';
import { VlProductsComponent } from './components/supplements/vl-products/vl-products.component';
import { ColumnStackedChartComponent } from './components/column-stacked-chart/column-stacked-chart.component';
import { CommonGridComponent } from './components/common-grid/common-grid.component';
import { CommonPopupComponent } from './components/common-popup/common-popup.component';
import { HealthCoachComponent } from './components/health-coach/health-coach.component';
import { VlOrdersComponent } from './components/supplements/vl-orders/vl-orders.component';
import { CommonModelComponent } from './components/common-model/common-model.component';
import { CommonModalWithGridComponent } from './components/common-modal-with-grid/common-modal-with-grid.component';
import { SalesByBrandComponent } from './components/supplements/sales-by-brand/sales-by-brand.component';
import { SalesByWarehouseComponent } from './components/supplements/sales-by-warehouse/sales-by-warehouse.component';
import { LabInvoicesComponent } from './components/labs/lab-invoices/lab-invoices.component';
import { SymptomReviewComponent } from '../patient/components/graphs/symptom-review/symptom-review.component';
import { SymptomReviewGraphComponent } from '../patient/components/graphs/symptom-review-graph/symptom-review-graph.component';
import { SymptomsReviewMultiplePatientsComponent } from '../patient/components/graphs/symptoms-review-multiple-patients/symptoms-review-multiple-patients.component';
import { AlertsComponent } from './components/supplements/alerts/alerts.component';
import { AddUpdateLabInvoiceComponent } from './components/labs/add-update-lab-invoice/add-update-lab-invoice.component';
import { ConfirmReleaseComponent } from './components/release-plan/confirm-release/confirm-release.component';
import { GroupColumnChartComponent } from './components/group-column-chart/group-column-chart.component';
import { UpdateProviderInvoiceComponent } from './components/provider-transactions/update-provider-invoice/update-provider-invoice.component';
import { TimesheetsComponent } from './components/timesheets/timesheets.component';
import { TimesheetAlertComponent } from './components/timesheets/timesheet-alert/timesheet-alert.component';
import { PayrollComponent } from './components/hr/payroll/payroll.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { OverrideChartsComponent } from './components/override-charts/override-charts.component';
import { DynamicBarChartComponent } from './components/dynamic-bar-chart/dynamic-bar-chart.component';
import { UpdateLeadComponent } from './components/leads/update-lead/update-lead.component';
import { CallLogPopupComponent } from './components/call-log-popup/call-log-popup.component';
import { CouponsUsedComponent } from './components/supplements/coupons-used/coupons-used.component';
import { ShowLeadsComponent } from './components/leads/show-leads/show-leads.component';
import { BrandsComponent } from './components/supplements/brands/brands.component';
import { NewBrandComponent } from './components/supplements/brands/new-brand/new-brand.component';
import { KnowledgeBaseComponent } from './components/knowledge-base/knowledge-base.component';
import { NewKnowledgeBaseComponent } from './components/knowledge-base/new-knowledge-base/new-knowledge-base.component';
import { KnowledgePageComponent } from './components/knowledge-base/knowledge-page/knowledge-page.component';
import { CreateTopicPopupComponent } from './components/knowledge-base/popup/create-topic-popup/create-topic-popup.component';
import { CreateSubtopicPopupComponent } from './components/knowledge-base/popup/create-subtopic-popup/create-subtopic-popup.component';
import { KbQuestionAnswerComponent } from './components/knowledge-base/kb-question-answer/kb-question-answer.component';
import { KbEntriesComponent } from './components/knowledge-base/kb-entries/kb-entries.component';
import { TopicSubtopicComponent } from './components/knowledge-base/topic-subtopic/topic-subtopic.component';
import { ShowMyTasksComponent } from './components/show-my-tasks/show-my-tasks.component';
import { ChecklistsComponent } from './components/hr/checklists/checklists.component';
import { NewUpdateChecklistComponent } from './components/hr/checklists/new-update-checklist/new-update-checklist.component';
import { EmployeeComponent } from './components/hr/employee/employee.component';
import { AllEmployeeComponent } from './components/hr/all-employee/all-employee.component';
import { TaskPopUpComponent } from './components/show-my-tasks/task-pop-up/task-pop-up.component';
import { RoleWiseAccessControlComponent } from './components/admin-access-controls/role-wise-access-control/role-wise-access-control.component';
import { EditStockComponent } from './components/supplements/stock-supplements/edit-stock/edit-stock.component';
import { VlNonSpecialProductsGirdComponent } from './components/supplements/vl-products/vl-non-special-products-grid/vl-non-special-products-grid';
import { PortalGlobalSettingsComponent } from './components/admin-access-controls/portal-global-settings/portal-global-settings.component';
import { NewPortalGlobalSettingComponent } from './components/admin-access-controls/portal-global-settings/new-portal-global-setting/new-portal-global-setting.component';
import { StaffComponent } from './components/admin-provider/staff/staff.component';
import { StaffPopupComponent } from './components/admin-provider/staff/popup/staff-popup/staff-popup.component';
import { WarehousesComponent } from './components/supplements/warehouses/warehouses.component';
import { NewUpdateWarehouseComponent } from './components/supplements/warehouses/new-update-warehouse/new-update-warehouse.component';
import { EmployeeBalancesComponent } from './components/hr/employee-balances/employee-balances.component';
import { KbReadStatusComponent } from './components/knowledge-base/kb-read-status/kb-read-status.component';
import { SurveyResponseComponent } from './components/survey-response/survey-response.component';
import { MissingFormsGridComponent } from './components/missingforms/missingforms/missing-forms-grid/missing-forms-grid.component';
import { EditSurveyResponseComponent } from './components/survey-response/edit-survey-response/edit-survey-response.component';
import { VlProductoutofStocksNotesComponent } from './components/supplements/stock-supplements/vl-productoutof-stocks-notes/vl-productoutof-stocks-notes.component';
import { OneTimeBindingDirective } from './directives/one-time-binding.directive';
import { SurveyPerformanceComponent } from './components/survey-response/survey-performance/survey-performance.component';


import { VlProductShippedComponent } from './components/supplements/stock-supplements/vl-product-shipped/vl-product-shipped.component';

import { CerboSupplimentsComponent } from './components/supplements/stock-supplements/cerbo-suppliments/cerbo-suppliments.component';
import { PriceDiffOfficeVitalLivComponent } from './components/supplements/stock-supplements/price-diff-office-vital-liv/price-diff-office-vital-liv.component';
import { RwShopifyProductComponent } from './components/supplements/stock-supplements/rw-shopify-product/rw-shopify-product.component';
import { VlProductShippedNotesComponent } from './components/supplements/stock-supplements/vl-product-shipped-notes/vl-product-shipped-notes.component';
import { UnsubscribeComponent } from './components/leads/unsubscribe/unsubscribe.component';
import { ErrorLogsMainComponent } from './components/error-log-pages/error-logs-main/error-logs-main.component';
import { AppoinmntswithinDaysComponent } from './components/appoinments/appoinmntswithin-days/appoinmntswithin-days.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ProductOrdersComponent } from './components/supplements/product-orders/product-orders.component';
import { NewEditProductOrderComponent } from './components/supplements/product-orders/new-edit-product-order/new-edit-product-order.component';
import { ProductsGridComponent } from './components/supplements/product-orders/products-grid/products-grid.component';
import { EmailsComponent } from './components/emails/emails.component';
import { UnsignedEncounterNoteComponent } from './components/unsigned-encounters/unsigned-encounter-note/unsigned-encounter-note.component';
import { PatientsGridComponent } from './components/patients-grid/patients-grid.component';
import { TaskNoteComponent } from './components/pastappoinments/task-note/task-note.component';
import { PatientsByLocationComponent } from './components/patients-by-location/patients-by-location.component';
import { EventRegistrationPanelComponent } from './components/update-market-event/event-registration-panel/event-registration-panel.component';
import { EventNoteComponent } from './components/update-market-event/event-note/event-note.component';
import { AllEmailsComponent } from './components/emails/all-emails/all-emails.component';
import { EmailPopupComponent } from './components/emails/email-popup/email-popup.component';
import { PastappoinmentswithindayComponent } from './components/pastappoinments/pastappoinmentswithinday/pastappoinmentswithinday.component';
import { LeadInteractionComponent } from './components/lead-interaction/lead-interaction.component';



@NgModule({
  declarations: [
    ProviderTransactionsComponent, 
    LeadsComponent, 
    NewLeadComponent, 
    AlertComponent, 
    AppoinmentsComponent,
    PatientNoteComponent ,
    ProviderAccessControlComponent,
    MissingFormsComponent,
    FilterArrayPipe,
    ReplaceStringPipe,
    ShortArrayPipe,
    MissingFormDataTypesPipe,
    UpdateLeadComponent,
    TabsPipe,
    OpenOrderComponent,
    RowsCountPipe,
    UnsignedEncountersComponent,
    SpinnerComponent,
    SortdatePipe,
    SumPipe,
    //SortnamePipe,
    ErrorLogsComponent,
    AppointPerHourComponent,
    AppointRecordCountComponent,
    AppointRecordCountHorizonComponent,
    SubscriptionCountComponent,
    CountPerSubscriptionComponent,
    PatientCountComponent,
    SupportTicketComponent,
    NewticketComponent,
    UpdateticketComponent,
    ProgressBarComponent,
    SubFilterPipe,
    SortObjectPipe,
    InputDialogComponent,
    PastappoinmentsComponent,
    ScheduleChangeUpdateComponent,
    TimeformatPipe,
    IdeasComponent,
    AdminProviderComponent,
    ProviderUpdateComponent,
    MarketingEventsComponent,
    NewMarketEventComponent,
    UpdateMarketEventComponent,
    LifeEventComponent,
    NewLifeEventComponent,
    UpdateLifeEventComponent,
    Date4Pipe,
    EmployeeProfileComponent,
    ReplaceLineBreaksPipe,
    StackedBarChartComponent,
    LeadConsultationAlertComponent,
    ReplaceLastdigitPipe,
    ReleasePlanComponent,
    NewReleasePlanComponent,
    UpdateReleaseComponent,
    PatientAlertComponent,
    ConfirmBoxComponent,
    BarChartComponent,
    IncompleteAddComponent,
    ConfirmationPopupComponent,
    SupplementsComponent,
    LabKitsComponent,
    PatientLabsComponent,
    SupplememntsInsightsComponent,
    SubscriptionAlertComponent,
    ActivePatientsComponent,
    InActivePatientsComponent,
    StateCodeMappingPipe,
    CustomTooltipDirective,
    FileUploadComponent,
    ReplacenPipe,
    InvoicesComponent,
    VlProductsComponent,
    ColumnStackedChartComponent,
    CommonGridComponent,
    CommonPopupComponent,
    HealthCoachComponent,
    VlOrdersComponent,
    CommonModelComponent,
    CommonModalWithGridComponent,
    SalesByBrandComponent,
    SalesByWarehouseComponent,
    LabInvoicesComponent,
    AddUpdateLabInvoiceComponent,
    SymptomReviewComponent,
    SymptomReviewGraphComponent,
    SymptomsReviewMultiplePatientsComponent,
    AlertsComponent,
    ConfirmReleaseComponent,
    GroupColumnChartComponent,
    UpdateProviderInvoiceComponent,
    TimesheetsComponent,
    TimesheetAlertComponent,
    PayrollComponent,
    SectionHeaderComponent,
    OverrideChartsComponent,
    OverrideChartsComponent,
    DynamicBarChartComponent,
    CallLogPopupComponent,
    CouponsUsedComponent,
    ShowLeadsComponent,
    BrandsComponent,
    NewBrandComponent,
    KnowledgeBaseComponent,
    NewKnowledgeBaseComponent,
    KnowledgePageComponent,
    CreateTopicPopupComponent,
    CreateSubtopicPopupComponent,
    KbQuestionAnswerComponent,
    KbEntriesComponent,
    TopicSubtopicComponent,
    ShowMyTasksComponent,
    ChecklistsComponent,
    NewUpdateChecklistComponent,
    EmployeeComponent,
    AllEmployeeComponent,
    TaskPopUpComponent,
    RoleWiseAccessControlComponent,
    EditStockComponent,
    VlNonSpecialProductsGirdComponent,
    PortalGlobalSettingsComponent,
    NewPortalGlobalSettingComponent,
    StaffComponent,
    StaffPopupComponent,
    WarehousesComponent,
    NewUpdateWarehouseComponent,
    EmployeeBalancesComponent,
    KbReadStatusComponent,
    MissingFormsGridComponent,
    SurveyResponseComponent,
    EditSurveyResponseComponent,
    VlProductoutofStocksNotesComponent,
    OneTimeBindingDirective,
    
    VlProductShippedComponent,
    CerboSupplimentsComponent,
    PriceDiffOfficeVitalLivComponent,
    RwShopifyProductComponent,
    VlProductShippedNotesComponent,
    UnsubscribeComponent,
    SurveyPerformanceComponent,
    ErrorLogsMainComponent,
    AppoinmntswithinDaysComponent,
    ProductOrdersComponent,
    NewEditProductOrderComponent,
    ProductsGridComponent,
    EmailsComponent,
    UnsignedEncounterNoteComponent,
    PatientsGridComponent,
    TaskNoteComponent,
    PatientsByLocationComponent,
    EventRegistrationPanelComponent,
    EventNoteComponent,
    AllEmailsComponent,
    EmailPopupComponent,
    PastappoinmentswithindayComponent,
    LeadInteractionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2CompleterModule,
    MaterialModule,
    QuillModule,
    OverlayModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    QuillModule,
    LeadsComponent,
    NewLeadComponent,
    AlertComponent,
    MissingFormsComponent,
    FilterArrayPipe,
    ReplaceStringPipe,
    MissingFormDataTypesPipe,
    Ng2CompleterModule,
    UpdateLeadComponent,
    TabsPipe,
    OpenOrderComponent,
    RowsCountPipe,
    UnsignedEncountersComponent,
    SpinnerComponent,
    SortdatePipe,
    SumPipe,
    ErrorLogsComponent,
    PatientNoteComponent,
    ScheduleChangeUpdateComponent,
    AppointPerHourComponent,
    AppointRecordCountComponent,
    AppointRecordCountHorizonComponent,
    SubscriptionCountComponent,
    CountPerSubscriptionComponent,
    PatientCountComponent,
    SupportTicketComponent,
    NewticketComponent,
    UpdateticketComponent,
    ProgressBarComponent,
    SubFilterPipe,
    ShortArrayPipe,
    SortObjectPipe,
    InputDialogComponent,
    PastappoinmentsComponent,
    TimeformatPipe,
    IdeasComponent,
    AdminProviderComponent,
    MarketingEventsComponent,
    NewMarketEventComponent,
    NewLifeEventComponent,
    LifeEventComponent,
    Date4Pipe,
    EmployeeProfileComponent,
    ReplaceLineBreaksPipe,
    StackedBarChartComponent,
    LeadConsultationAlertComponent,
    ReplaceLastdigitPipe,
    ReleasePlanComponent,
    PatientAlertComponent,
    ConfirmBoxComponent,
    BarChartComponent,
    ConfirmationPopupComponent,
    SupplementsComponent,
    LabKitsComponent,
    PatientLabsComponent,
    SupplememntsInsightsComponent,
    SubscriptionAlertComponent,
    ActivePatientsComponent,
    InActivePatientsComponent,
    StateCodeMappingPipe,
    CustomTooltipDirective,
    OverlayModule,
    FileUploadComponent,
    ReplacenPipe,
    InvoicesComponent,
    VlProductsComponent,
    ColumnStackedChartComponent,
    CommonGridComponent,
    CommonPopupComponent,
    HealthCoachComponent,
    VlOrdersComponent,
    CommonModelComponent,
    CommonModalWithGridComponent,
    SalesByBrandComponent,
    SalesByWarehouseComponent,
    LabInvoicesComponent,
    AddUpdateLabInvoiceComponent,
    ProviderTransactionsComponent,
    SymptomReviewComponent,
    SymptomReviewGraphComponent,
    SymptomsReviewMultiplePatientsComponent,
    AlertsComponent,
    GroupColumnChartComponent,
    UpdateProviderInvoiceComponent,
    TimesheetsComponent,
    PayrollComponent,
    SectionHeaderComponent,
    OverrideChartsComponent,
    DynamicBarChartComponent,
    CouponsUsedComponent,
    ShowLeadsComponent,
    BrandsComponent,
    NewBrandComponent,
    KnowledgeBaseComponent,
    NewKnowledgeBaseComponent,
    KnowledgePageComponent,
    CreateTopicPopupComponent,
    CreateSubtopicPopupComponent,
    KbQuestionAnswerComponent,
    KbEntriesComponent,
    TopicSubtopicComponent,
    ShowMyTasksComponent,
    ChecklistsComponent,
    NewUpdateChecklistComponent,
    EmployeeComponent,
    AllEmployeeComponent,
    RoleWiseAccessControlComponent,
    StaffComponent,
    StaffPopupComponent,
    EmployeeBalancesComponent,
    KbReadStatusComponent,
    SurveyResponseComponent,
    OneTimeBindingDirective,
    VlProductShippedComponent,
    UnsubscribeComponent,
    VlProductShippedNotesComponent,
    SurveyPerformanceComponent,
    ErrorLogsMainComponent,
    EmailsComponent,
    PatientsGridComponent,
    TaskNoteComponent,
    PatientsByLocationComponent
  ],
  providers:[
    MissingFormDataTypesPipe
  ]
})
export class SharedModule { }
