<h1 mat-dialog-title [ngStyle]="{'background-color':headerColor}">Multi-Factor Authentication </h1>
<form [formGroup]="verifyForm" (submit)="onClick()" class="intellectportal_form intellectportal_login_form formchnge">
<div mat-dialog-content>
  <div class="content-title">
    <p>
      <span class="span-title">Enter Verification Code</span>
    </p>
    <p>
      <span class="span-label">A verification code has been sent to {{maskedPhone}}.</span>
    </p>

  </div>
  <div class="content-data">
    <!-- <form [formGroup]="verifyForm" class="intellectportal_form intellectportal_login_form"> -->
      <mat-card class="mat-card mat-focus-indicator paddingTop50">
       
        
        <p class="code">
          <!-- <label for="intellectportal_username"> Code</label>
          <input type="text" id="intellectportal_username" name="intellectportal_username" class="user-input" formControlName="code"/> -->
          <mat-form-field appearance="outline"floatLabel="always">
            <mat-label for="intellectportal_username">Verification Code</mat-label>
            <input matInput type="text" id="intellectportal_username" name="intellectportal_username" class="user-input"
              formControlName="code">

          </mat-form-field>

        </p>

       
      </mat-card>
      <p class="time" [style.color]="areTenSecsRemainings ? 'red' : ''">
        <mat-icon aria-hidden="false" class="mat-icon" aria-label="Example timer icon">timer</mat-icon>
        <span class="counte">
          {{
            minie 
          }} </span> <span class="counte-label">m</span><span class="counte">{{ secse  }}
        </span> <span class="counte-label">s</span>
      </p>
      <p class="resend">
        <span class="resend-span">Didn't received the verfication code? <a (click)="resend()" class="send-a">Click here
            to send another one.</a> </span>
      </p>
      <div class="intellectportal-login-message-container" *ngIf="hasErrorWithVerify">
        <p class="message-here">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error">Invalid Phone Number/Unable
            to Verify Phone.</span><br />
        </p>
      </div>
      <div class="intellectportal-login-message-container" *ngIf="hasErrorCode">
        <p class="message-here">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error">Code you entered is
            invalid/Expired/Already Used.</span><br />
        </p>
      </div>
      <div class="intellectportal-login-message-container" *ngIf="hasErrorWithCount">
        <p class="message-here">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error"> Max send checks / Exceeds
            verfication requests daily Limits</span><br />
        </p>
      </div>
      <div class="intellectportal-login-message-container" *ngIf ="hasErrorWithLoginVerify">
        <p class="message-here">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error">Unable to get verfication code/ Unable to Proceed.</span><br />
        </p>
      </div>
      <div class="intellectportal-login-message-container" *ngIf ="hasErrorWithLoginCount">
        <p class="message-here">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><span class="error"> Too many concurrent verification code requests/ Exceeds verification code requests daily Limits</span><br />
        </p>
      </div>


  </div>


</div>

<div mat-dialog-actions>
  <button class="btn-cancel"  type="button" mat-raised-button (click)="onNoClick()" [ngStyle]="{'background-color':cancelButton}">Cancel</button>
  <button class="btn-continue" type="submit" mat-raised-button color="primary" [disabled]="verifyForm.invalid" [ngStyle]="{'background-color':okButton}"
   >Continue</button>
  <!-- <button mat-button [mat-dialog-close]="" cdkFocusInitial>Get City</button> -->
</div>
</form>