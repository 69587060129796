import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/shared/services/profile/profile.service';
import { SalesforceService } from 'src/app/core';
import { TaskService } from 'src/app/shared/services/task.service';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { CryptoService } from 'src/app/shared/services/crytoservice/crypto-service.service';
import { TabServiceService } from 'src/app/shared/services/tabs/tab-service.service';
import { UserDetailsService } from 'src/app/admin/services/user-details.service';
import { GlobalSettingsService } from 'src/app/shared/services/global-settings/global-settings.service';
import { PortalSettingsService } from './shared/services/global-settings/portal-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  usersDetails: any = [];
  userRole;
  roleDataArray: any;
  userInfoData: any;
  constructor(
    private titleService: Title,
    private profileSvc: ProfileService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private userDetailsSvc: UserDetailsService,
    private tabsSvc: TabServiceService,
    private sfSvc: SalesforceService,
    private globalSettingSvc:GlobalSettingsService,
    private portalSettingSvc: PortalSettingsService  // DONT REMOVE THIS DEPENDANCY
  ) { }

  ngOnInit(): void {
    if (window.performance) {
      //  console.info("window.performance works fine on this browser");
    }
    // console.info(performance.navigation.type);
    if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      this.globalSettingSvc.getAllColors().subscribe((res:any) => {
        
      });
      // console.info( "This page is reloaded" );
      this.userInfoData = {} 
      this.sfSvc.getRoleData().subscribe((roledata) => {
        this.roleDataArray = roledata['records']
        if (this.roleDataArray.length) {
          this.roleDataArray.forEach(element => {
            if (data.Staff__c == element.Role__c) {
              this.userInfoData['role'] = element.Role__c;
            }
          });
        }
      })
      let data: any = [];
      if(sessionStorage.getItem('userData')){
      data = JSON.parse(CryptoService.decryptUsingAES256(sessionStorage.getItem('userData')));
      TaskService.task(data);

     }

      this.tabsSvc.getAllTabs().subscribe(() => {
       
      });

      
      
    } else {
      console.info("This page is not reloaded");
    }
  }
}
