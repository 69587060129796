import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NAMES } from '../../constants/portal-settings.constants';
import { GlobalSettings } from '../../model';

/**
 * NOTE-
 * Use getter properties instead of directly accessing class properties
 */

@Injectable({
  providedIn: 'root'
})
export class PortalSettingsService {
  private settings = {};
  constructor(private http: HttpClient) {
    this.setObject();
    // This should gets called only once thought project
    this.getSettings();
  }

  // set empty data
  setObject() {
    const data = {
      Colors__c: '',
      Id: '',
      Name: '',
      Twilio_Attempts__c: null,
      User_Timeout__c: null,
      Copyright__c:''
    };
    Object.keys(NAMES).forEach((x: any) => {
      this.settings[NAMES[x]] = new GlobalSettings(data);
    });
  }


  getSettings() {
    console.log("PORTAL SET----------------------");
    const adminQuery = `SELECT Id, Name, User_Timeout__c, Twilio_Attempts__c, Colors__c, Copyright__c  FROM Portal_Global_Settings__c`;
    const data = environment.SF_QUERY + adminQuery;
    const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';

    return this.http.post(uri, data)
      .subscribe((resp: any) => {
        if (resp && resp.records) {
          resp.records.forEach((rules: any) => {
            this.settings[rules.Name] = new GlobalSettings(rules);
          });
        }
      });
  }

  /**
   * Entire setting object
   * @returns object
   */
  getAllSettings() {
    return this.settings;
  }

  /**
   * AllOK object with its properties
   * @returns object
   */
  getAllOK() {
    return this.settings[NAMES.ALL_OK];
  }
  getAllOKColor() {
    return this.settings[NAMES.ALL_OK].color;
  }

  /**
   * ButtonCancel object with its properties
   * @returns object
   */
  getButtonCancel() {
    return this.settings[NAMES.BUTTON_CANCEL];
  }
  getButtonCancelColor() {
    return this.settings[NAMES.BUTTON_CANCEL].color;
  }

  /**
   * ButtonOK object with its properties
   * @returns object
   */
  getButtonOK() {
    return this.settings[NAMES.BUTTON_OK];
  }
  getButtonOKColor() {
    return this.settings[NAMES.BUTTON_OK].color;
  }

  /**
   * FieldBG object with its properties
   * @returns object
   */
  getFieldBG() {
    return this.settings[NAMES.FIELD_BG];
  }
  getFieldBGColor() {
    return this.settings[NAMES.FIELD_BG].color;
  }

  /**
   * FieldBorder object with its properties
   * @returns object
   */
  getFieldBorder() {
    return this.settings[NAMES.FIELD_BORDER];
  }
  getFieldBorderColor() {
    return this.settings[NAMES.FIELD_BORDER].color;
  }

  /**
   * FieldBorderHighlight object with its properties
   * @returns object
   */
  getFieldBorderHighlight() {
    return this.settings[NAMES.FIELD_BORDER_HIGHLIGHT];
  }
  getFieldBorderHighlightColor() {
    return this.settings[NAMES.FIELD_BORDER_HIGHLIGHT].color;
  }

  /**
   * FieldLabel object with its properties
   * @returns object
   */
  getFieldLabel() {
    return this.settings[NAMES.FIELD_LABEL];
  }
  getFieldLabelColor() {
    return this.settings[NAMES.FIELD_LABEL].color;
  }

  /**
   * FieldPadding object with its properties
   * @returns object
   */
  getFieldPadding() {
    return this.settings[NAMES.FIELD_PADDING];
  }
  getFieldPaddingColor() {
    return this.settings[NAMES.FIELD_PADDING].color;
  }

  /**
   * GlobalSetting object with its properties
   * @returns object
   */
  getGlobalSetting() {
    return this.settings[NAMES.GLOBAL_SETTING];
  }
  getGlobalSettingColor() {
    return this.settings[NAMES.GLOBAL_SETTING].color;
  }
  getGlobalSettingTwilioAttempts() {
    return this.settings[NAMES.GLOBAL_SETTING].twilioAttempts;
  }
  getGlobalSettingUserTimeout() {
    return this.settings[NAMES.GLOBAL_SETTING].userTimeout;
  }
  getGlobalSettingCopyRights() {
    return this.settings[NAMES.GLOBAL_SETTING].copyright;
  }

  /**
   * LeftPanelBG object with its properties
   * @returns object
   */
  getLeftPanelBG() {
    return this.settings[NAMES.LEFT_PANEL_BG];
  }
  getLeftPanelBGColor() {
    return this.settings[NAMES.LEFT_PANEL_BG].color;
  }

  /**
   * LeftPanelHighlight object with its properties
   * @returns object
   */
  getLeftPanelHighlight() {
    return this.settings[NAMES.LEFT_PANEL_HIGHLIGHT];
  }
  getLeftPanelHighlightColor() {
    return this.settings[NAMES.LEFT_PANEL_HIGHLIGHT].color;
  }

  /**
   * LeftPanelHighlightFont object with its properties
   * @returns object
   */
  getLeftPanelHighlightFont() {
    return this.settings[NAMES.LEFT_PANEL_HIGHLIGHT_FONT];
  }
  getLeftPanelHighlightFontColor() {
    return this.settings[NAMES.LEFT_PANEL_HIGHLIGHT_FONT].color;
  }

  /**
   * MainPanelBG object with its properties
   * @returns object
   */
  getMainPanelBG() {
    return this.settings[NAMES.MAIN_PANEL_BG];
  }
  getMainPanelBGColor() {
    return this.settings[NAMES.MAIN_PANEL_BG].color;
  }

  /**
   * PatientAlert object with its properties
   * @returns object
   */
  getPatientAlert() {
    return this.settings[NAMES.PATIENT_ALERT];
  }
  getPatientAlertColor() {
    return this.settings[NAMES.PATIENT_ALERT].color;
  }
  getPatientAlertTwilioAttempts() {
    return this.settings[NAMES.PATIENT_ALERT].twilioAttempts;
  }
  getPatientAlertUserTimeout() {
    return this.settings[NAMES.PATIENT_ALERT].userTimeout;
  }

  /**
   * PopupHeader object with its properties
   * @returns object
   */
  getPopupHeader() {
    return this.settings[NAMES.POPUP_HEADER];
  }
  getPopupHeaderColor() {
    return this.settings[NAMES.POPUP_HEADER].color;
  }

  /**
   * ProviderAlert object with its properties
   * @returns object
   */
  getProviderAlert() {
    return this.settings[NAMES.PROVIDER_ALERT];
  }
  getProviderAlertColor() {
    return this.settings[NAMES.PROVIDER_ALERT].color;
  }

  /**
   * SectionHeader object with its properties
   * @returns object
   */
  getSectionHeader() {
    return this.settings[NAMES.SECTION_HEADER];
  }
  getSectionHeaderColor() {
    return this.settings[NAMES.SECTION_HEADER].color;
  }

  /**
   * StaffAlert object with its properties
   * @returns object
   */
  getStaffAlert() {
    return this.settings[NAMES.STAFF_ALERT];
  }
  getStaffAlertColor() {
    return this.settings[NAMES.STAFF_ALERT].color;
  }

  // get Default color 
  getDefault(){
    return this.settings[NAMES.DEFAULT]
  }
  getDefaultColor(){
    return this.settings[NAMES.DEFAULT].color;
  }

  // get User color 
  getUserColor(name){
    return this.settings[name]?.color;
  }

  getAdminAlertColor() {
    return this.settings[NAMES.ADMIN_ALERT].color;
  }
}
