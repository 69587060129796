export const UserRoles = {
    ADMIN: 'Admin',
    PROVIDER: 'Provider',
    DEVELOPER: 'Developer',
    MA: 'MA',
    MD: 'MD',
    OFFICE_MGR: 'Office Mgr',
    FRONT_OFFICE: 'Front Office',
    ECOMMERCE: 'ECommerce',
    MSO_PROVIDER: 'MSO Provider',
    HEALTH_COACH: 'Health Coach',
    ECOMMERCE_ANALYST: 'ECommerce Analyst',
}

export const Menus = {
    Dashboard: 'Dashboard',
    Appointments: 'Appointments',
    //Access_Control:'Access_Control',
    Leads: 'Leads',
    // Missing_Forms:'Missing_Forms',
    Open_Orders: 'Open_Orders',
    Patients: 'Patients',
    Subscriptions: 'Subscriptions',
    Support_Tickets: 'Support_Tickets',
    //Unsigned_Encounters:'Unsigned_Encounters',
    //Time_Off: 'Time_Off',
    Office_Team: 'Office_Team',
    Marketing: 'Marketing',
    Admin: 'Admin',
    Compliance: 'Compliance',
    Financial: 'Financial',
    Supplements: 'Supplements',
    Labs: 'Labs',
    Company: 'Company',
    HR:'HR',
    Knowledge_Base:'Knowledge_Base',
    Survey: 'Survey',
    Emails: 'Emails',
    Interactions:'Interactions'
}

export const SubMenus = {
    All_Patients: 'All_Patients',
    New_Patients: 'New_Patients',
    Access_Control: 'Access_Control',
    Subscription_Contracts: 'Subscription_Contracts',
    Active_Subscriptions: 'Active_Subscriptions',
    Upcoming_Appts: 'Upcoming_Appts',
    Past_Appts: 'Past_Appts',
    Patient_Visits: 'Patient_Visits',
    Events: 'Events',
    Payments: 'Payments',
    Subscriptions: 'Subscriptions',
    Subscription_Utilization: 'Subscription_Utilization',
    Users: 'Users',
    Error_Logs: 'Error_Logs',
    Login_History: 'Login_History',
    Release_Plans: 'Release_Plans',
    Prospective_Patient: 'Prospective_Patient',
    Alerts: 'Alerts',
    Provider_Licenses: 'Provider_Licenses',
    Performance: 'Performance',
    Companies: 'Companies',
    Stock_Supplements: 'Stock_Supplements',
    Patient_Labs: 'Patient_Labs',
    Lab_Kits: 'Lab_Kits',
    Supplement_Insights: "Supplement_Insights",
    Missing_Forms: 'Missing_Forms',
    Unsigned_Encounters: 'Unsigned_Encounters',
    Inactive_Patients: 'Inactive_Patients',
    Active_Patients: 'Active_Patients',
    Out_Of_State_Patients: 'Out_Of_State_Patients',
    Assets: 'Assets',
    VitaLiving_Products: 'VitaLiving_Products',    
    Transactions:'Transactions',
    Ecommerce: 'Ecommerce',
    KPI: 'KPI',
    Rep_Visits:'Rep_Visits',
    Health_Coaching:'Health_Coaching',
    VitaLiving_Orders: 'VitaLiving_Orders',
    Sales_by_Warehouse: 'Sales_by_Warehouse',
    Sales_by_Brand: 'Sales_by_Brand',
    Health_Coach:'Health_Coach',
    Lab_Invoices: 'Lab_Invoices',
    Vita_Living:'Vita_Living',
    Track_Progress: 'Track_Progress',
    Supplement_Alerts: 'Supplement_Alerts',
    Appt_Timeliness:'Appt_Timeliness',
    Office_Team:'Office_Team',
    Active_Providers:'Active_Providers',
    Front_Office:'Front_Office',
    Missing_Payments: 'Missing_Payments',
    Provider_Invoices: 'Provider_Invoices',
    TimeSheet:'TimeSheet',
    Payroll:'Payroll',
    Coupons_Used:'Coupons_Used',
    Brands:'Brands',
    Topics_SubTopics:'Topics_SubTopics',
    KB:'KB',
    KB_Entries:'KB_Entries',
    Checklists:'Checklists',
    Lead_Metrics:'Lead_Metrics',
    Employees:'Employees',
    Time_Off: 'Time_Off',
    Staff_Dashboard: "Staff_Dashboard",
    Providers_Pt_Access:"Providers_Pt_Access",
    State_Registration: "State_Registration",
    Waitlist_Patients: "Waitlist_Patients",
    Portal_Global_Settings:'Portal_Global_Settings',
    Staff:'Staff',
    Warehouses: 'Warehouses',
    Employee_Balances: 'Employee_Balances',
    KB_Read_Status:'KB_Read_Status',
    Survey_Responses :"Survey_Responses",
    Surveys:"Surveys",
    Patient_Billing:"Patient_Billing",
    Recent_Payments: "Recent_Payments",
    Holidays: 'Holidays',
    Emails: "Emails",
    Provider_Schedules :"Provider_Schedules",
    Follow_Up_Patients: "Follow_Up_Patients",
    Bank_Deposits:"Bank_Deposits",
    Product_Orders: "Product_Orders",
    Phone_Logs:"Phone_Logs",
    Open_Orders: "Open_Orders",
    Patient_Progress:"Patient_Progress",
    My_Emails:"My_Emails",
    All_Emails:"All_Emails",
    Patient_Health_Trends:"Patient_Health_Trends",
}

export const PatientMenu = {
    Dashboard: 'Dashboard',
    // Account: 'Account',
    Appointment: "Appointment",
    Billing: 'Billing',
    Emails: 'Emails',
    Graphs: 'Graphs',
    Lab_Documents: 'Lab_Documents',
    Life_Event: 'Life_Event',
    Marketing: 'Marketing',
    Medications: 'Medications',
    Messages: 'Messages',
    Open_Orders: 'Open_Orders',
    Patient_Forms: 'Patient_Forms',
    Pharmacies: 'Pharmacies',
    Provider_Notes: 'Provider_Notes',
    Subscription: 'Subscription',
    Supplements: 'Supplements',
    Vaccine_Records: 'Vaccine_Records',
    Vitals: 'Vitals'
}

export const PatientSubMenu = {
    Patient_Matrix: 'Patient_Matrix',
    Patient_Timeline: 'Patient_Timeline',
    Readiness_Assessment: 'Readiness_Assessment',
    Symptom_Review : 'Symptom_Review',
    Toxicity_Questionnaire: 'Toxicity_Questionnaire',
    Thyroid_Scores: 'Thyroid_Scores'
}