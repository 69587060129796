import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { SpinnerService } from '../shared/services/spinner.service';

import { environment } from '../../environments/environment';
import { ProfileService } from '../shared/services/profile/profile.service';
import { CryptoService } from '../shared/services/crytoservice/crypto-service.service';

import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private profileSvc: ProfileService,public spinnerService: SpinnerService,private router: Router) {
        //this.href = this.router.url;
      
     }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.spinnerService.show();
        request = request.clone({
            setHeaders: {
                'mode': 'cors'
            },
            body: request.body && (request.headers.get('FormType') !== 'file') ? {data: CryptoService.encryptUsingAES256(JSON.stringify(request.body))} : request.body
        });
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                   // if(!(this.router.url =='/adminDashboard/past-appoinments' || this.router.url == '/adminDashboard/appoinments')){
                    this.spinnerService.hide();
                   
                   // }
                }
                return event;
            }),
            catchError(err => {
                this.spinnerService.hide();
                return throwError(err);
            }));
        
    }
}