import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { CerboService } from '../cerbo/cerbo.service';
import {environment} from 'src/environments/environment';
import * as CryptoJS from "crypto-js"
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, 
    private cerboSvc: CerboService) { }

    hashcode(val) {
      return CryptoJS.SHA1(val).toString(CryptoJS.enc.Hex);
    }

  login(username, password, loginAs) {    
    let loginDetails = null;

    
    if (loginAs === 'provider') {
      loginDetails = {
        'user': username,
        'password': password,
      };
    }else if (loginAs === 'ecommerce') {
      loginDetails = {
        'user': username,
        'password': password,
      };
    } else {
      loginDetails = {
        'username': username,
        'password': password,
      };
    }
    const postData = {
      loginDetails,
      loginAs
    }

   
  
    
    
   

console.log("postData", postData)
     if (loginAs === 'ecommerce') {
      let hashPwd = this.hashcode(loginDetails.password);
      // console.log("-------Hash code---",loginDetails.password, hashPwd);
       let adminQuery = `SELECT Id, Name, Name__c,UserName__c, Email__c, Password__c, Ticket_Access__c, EMR_Bypass__c FROM OfficeUser__c 
                          where UserName__c='${loginDetails.user}' and (Password__c='${hashPwd}' or Password__c='${loginDetails.password}')`;
      const data = environment.SF_QUERY + adminQuery; 
		  const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
      console.log("this.http.post(uri, data)", this.http.post(uri, data))
		   return this.http.post(uri, data) 
       
    }else{

  return this.getTest(loginDetails.user || loginDetails.username, loginDetails.password).pipe(
  switchMap(response => {

    let rec:any = response;

    // Process the response if needed
    if(rec.records[0].EMR_Bypass__c === false){
      return this.cerboSvc.authoriseUser(postData);
    }else{
      let adminQuery = `SELECT Id, Name, Name__c,UserName__c, Email__c, Password__c, Ticket_Access__c, EMR_Bypass__c FROM OfficeUser__c 
      where UserName__c='${loginDetails.user}' and Password__c='${loginDetails.password}'`;
      const data = environment.SF_QUERY + adminQuery; 
      const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
      console.log("this.http.post(uri, data)", this.http.post(uri, data))
      return this.http.post(uri, data) 
    }
    
  }),
  catchError(error => {
    console.error("Error during getTest", error);
    return of(null); // Return a null observable or handle the error as needed
  })
);
    } 
  }

  getTest(user,hashPwd){
    let adminQuery = `SELECT Id, Name, Name__c,UserName__c, Email__c, Password__c, Ticket_Access__c, EMR_Bypass__c 
                      FROM OfficeUser__c 
                      WHERE UserName__c='${user}'`;
    const data = environment.SF_QUERY + adminQuery;
    const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
    return this.http.post(uri, data);
  }
getOtp(data){
  const uri = environment.NODE_SERVICE_BASE_URL+environment.NODE_SERVICE_API_END_POINT+'/otp';
  return this.http.post(uri, data);
}

returnClientIP(){
  const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/client-ip';
  return this.http.get(uri);
}

verifyOtp(data){
  const uri = environment.NODE_SERVICE_BASE_URL+environment.NODE_SERVICE_API_END_POINT+'/verify';
  return this.http.post(uri, data);
}

getLoginpageImages() {
    let adminQuery = `SELECT Id, Name, Image_Text_1__c, Image_Text_2__c, Image_Text_3__c, Position__c FROM Portal_Image_Config__c`;
    const data = environment.SF_QUERY + adminQuery; 
    const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
    return this.http.post(uri, data);
}

loginHistory(data){
    const postData = {
        api: environment.SF_ERROR_LOGS_ENDPOINT + 'Login_History__c',
        data,
        type: 'POST'
      }
      const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/post-data';

    return this.http.post(uri, postData);
  }

  sendLogoutDateTime() {
    const data = {
      "Logout_Time__c": new Date().toISOString()
    };
    const postData = {
      api: environment.SF_ERROR_LOGS_ENDPOINT + 'Login_History__c/' + `${localStorage.getItem('loginHistoryId')}`,
      data,
      type: 'PATCH'
    };
    const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/post-data';

    return this.http.post(uri, postData);
  }

getUserPlatfromDetails(){

    let unknown = '-';

    let nVer = navigator.appVersion;
    let nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    if ((verOffset = nAgt.indexOf('Opera')) != -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf('Version')) != -1) {
            version = nAgt.substring(verOffset + 8);
        }
    }

    if ((verOffset = nAgt.indexOf('OPR')) != -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 4);
    }

    else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
        browser = 'Microsoft Legacy Edge';
        version = nAgt.substring(verOffset + 5);
    } 

    else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
        browser = 'Microsoft Edge';
        version = nAgt.substring(verOffset + 4);
    }

    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(verOffset + 5);
    }

    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
        browser = 'Chrome';
        version = nAgt.substring(verOffset + 7);
    }

    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
        browser = 'Safari';
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf('Version')) != -1) {
            version = nAgt.substring(verOffset + 8);
        }
    }

    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
        browser = 'Firefox';
        version = nAgt.substring(verOffset + 8);
    }

    else if (nAgt.indexOf('Trident/') != -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    }

    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() == browser.toUpperCase()) {
            browser = navigator.appName;
        }
    }

    if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
        version = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }



    let os = unknown;
    let clientStrings = [
        {s:'Windows 10', r:/(Windows 10.0|Windows NT 10.0)/},
        {s:'Windows 8.1', r:/(Windows 8.1|Windows NT 6.3)/},
        {s:'Windows 8', r:/(Windows 8|Windows NT 6.2)/},
        {s:'Windows 7', r:/(Windows 7|Windows NT 6.1)/},
        {s:'Windows Vista', r:/Windows NT 6.0/},
        {s:'Windows Server 2003', r:/Windows NT 5.2/},
        {s:'Windows XP', r:/(Windows NT 5.1|Windows XP)/},
        {s:'Windows 2000', r:/(Windows NT 5.0|Windows 2000)/},
        {s:'Windows ME', r:/(Win 9x 4.90|Windows ME)/},
        {s:'Windows 98', r:/(Windows 98|Win98)/},
        {s:'Windows 95', r:/(Windows 95|Win95|Windows_95)/},
        {s:'Windows NT 4.0', r:/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
        {s:'Windows CE', r:/Windows CE/},
        {s:'Windows 3.11', r:/Win16/},
        {s:'Android', r:/Android/},
        {s:'Open BSD', r:/OpenBSD/},
        {s:'Sun OS', r:/SunOS/},
        {s:'Chrome OS', r:/CrOS/},
        {s:'Linux', r:/(Linux|X11(?!.*CrOS))/},
        {s:'iOS', r:/(iPhone|iPad|iPod)/},
        {s:'Mac OS X', r:/Mac OS X/},
        {s:'Mac OS', r:/(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
        {s:'QNX', r:/QNX/},
        {s:'UNIX', r:/UNIX/},
        {s:'BeOS', r:/BeOS/},
        {s:'OS/2', r:/OS\/2/},
        {s:'Search Bot', r:/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
    ];
    for (let id in clientStrings) {
        let cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
            os = cs.s;
            break;
        }
    }

    let osVersion:any = unknown;

    if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = 'Windows';
    }

    switch (os) {
        case 'Mac OS':
        case 'Mac OS X':
        case 'Android':
            osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
            break;

        case 'iOS':
            osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
            osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
            break;
    }
    
  
  let platfromObj = {
    browser: browser,
    browserVersion: version,
    browserMajorVersion: majorVersion,
    os: os,
    osVersion: osVersion,	
  }

  let platfromDetails = {
  OS: platfromObj.os +' '+ platfromObj.osVersion,
  Browser:  platfromObj.browser +' '+ platfromObj.browserMajorVersion + ' (' + platfromObj.browserVersion + ')'
  }  
  return platfromDetails;
}

}
