import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './user/login/login.component';
import { AuthGuard } from './helpers/auth.guard';
import { PhoneVerificationComponent } from './user/phone-verification/phone-verification.component';

const patientModule = () => import('./patient/patient.module').then(x => x.PatientModule);

const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);

const surveyModule = () => import('./survey/survey.module').then(x => x.SurveyModule);

const GlobalModule = () => import('./global/global.module').then(x => x.GlobalModule);

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'survey/:id', loadChildren: surveyModule },
  { path: 'unsubscribe/:id', loadChildren: GlobalModule},
  { path: 'phone-verification', component: PhoneVerificationComponent},

  { path: 'adminDashboard', loadChildren: adminModule, canActivate: [AuthGuard]  },
  //{path:'dashboard',component: DashboardComponent},

  { path: 'patientDashboard', loadChildren: patientModule, canActivate: [AuthGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
