export class SymptonReviewModel {
     _date: string;
     _section1: number;
     _section2: number;
     _section3: number;
     _section4: number;
     _section5: number;
     _section6: number;
     _section7: number;
     _section8: number;
     _section9: number;
     _section10: number;
     _section11: number;
     _id:string;
     _name:string;
     _status:string;

    constructor(raw?) {
        this._date = raw.Date_Responded__c || "";
        this._section1 = raw.Section_1_Total__c || 0;
        this._section2 = raw.Section_2_Total__c || 0;
        this._section3 = raw.Section_3_Total__c || 0;
        this._section4 = raw.Section_4_Total__c || 0;
        this._section5 = raw.Section_5_Total__c || 0;
        this._section6 = raw.Section_6_Total__c || 0;
        this._section7 = raw.Section_7_Total__c || 0;
        this._section8 = raw.Section_8_Total__c || 0;
        this._section9 = raw.Section_9_Total__c || 0;
        this._section10 = raw.Section_10_Total__c || 0;
        this._section11 = raw.Section_11_Total__c || 0;
        this._id = raw.Id || "";
        this._name = raw.Name || "";
        this._status = raw.Status__c || "";
    }

    public id() {
        return this._id;
    }
    public name() {
        return this._name;
    }
    public status() {
        return this._status;
    }

    public date() {
        return this._date;
    }
    public section1() {
        return this._section1;
    }
    public section2() {
        return this._section2;
    }
    public section3() {
        return this._section3;
    }
    public section4() {
        return this._section4;
    }
    public section5() {
        return this._section5;
    }
    public section6() {
        return this._section6;
    }
    public section7() {
        return this._section7;
    }
    public section8() {
        return this._section8;
    }
    public section9() {
        return this._section9;
    }
    public section10() {
        return this._section10;
    }
    public section11() {
        return this._section11;
    }
}
