import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, share } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingsService {
  staffAlertColor:any;
  globalColor:any = [];
  AppointmentDelayThreshold:any;
  constructor(private http: HttpClient) {}

  getGlobalSetting() {
    const obj = {providerAlert:'',patientAlert:'',allOk:'',staffAlert:'',PopupHeader:'',buttonOK:'',buttonCancel:''}
    const adminQuery = `SELECT Id, Name, User_Timeout__c, Twilio_Attempts__c, Colors__c, Copyright__c  FROM Portal_Global_Settings__c`;//, , Appointment_Delay_Threshold__c 
    const data = environment.SF_QUERY + adminQuery;
    const uri =
      environment.NODE_SERVICE_BASE_URL +
      environment.NODE_SERVICE_API_END_POINT +
      '/execute-query';
    return this.http.post(uri, data).pipe(map((data: any) => {
    // console.log("Global setting",data);
        this.globalColor = data;
      data.records.forEach(element => {
        if(element.Name == 'Provider_Alert'){
          obj.providerAlert = element.Colors__c;
        }else if(element.Name == 'Patient_Alert'){
          obj.patientAlert = element.Colors__c;
        }else if(element.Name == 'All_OK'){
          obj.allOk =  element.Colors__c;
        }else if(element.Name == 'Staff_Alert'){
          obj.staffAlert = element.Colors__c;
        }else if(element.Name == 'Popup_Header'){
          obj.PopupHeader = element.Colors__c;
        }else if(element.Name == 'Button_OK'){
          obj.buttonOK = element.Colors__c;
        }else if(element.Name == 'Button_Cancel'){
          obj.buttonCancel = element.Colors__c;
        }

      });

    //  this.AppointmentDelayThreshold  = 10;//data.records[0].Appointment_Delay_Threshold__c
     // console.log("obj..........",data.records[0].Appointment_Delay_Threshold__c);
     

    // console.log(" this.globalColor",  this.globalColor)
      return obj;
    }));

   // return obj;
  }

  public getAllColors() {
    return this.getGlobalSetting();
  }



  public  getStaffAlert(){
    if(this.globalColor.length != 0){
    let staffColor = this.globalColor.records.filter(x => x.Name == "Staff_Alert");
    return staffColor[0].Colors__c;
       }
  }
  public  getProviderAlert(){
    if(this.globalColor.length != 0){
    let staffColor = this.globalColor.records.filter(x => x.Name == "Provider_Alert");
    return staffColor[0].Colors__c;
       }
  }

  public  getpatientAlert(){
    if(this.globalColor.length != 0){
    let staffColor = this.globalColor.records.filter(x => x.Name == "Patient_Alert");
    return staffColor[0].Colors__c;
       }
  }

  public  getAllOk(){
    if(this.globalColor.length != 0){
    let staffColor = this.globalColor.records.filter(x => x.Name == "All_OK");
    return staffColor[0].Colors__c;
       }
  }

  public getpopupHeader(){
    if(this.globalColor.length != 0){
    let popupHeader = this.globalColor.records.filter(x => x.Name == "Popup_Header");
    return popupHeader[0].Colors__c;
       }
  }

  public getButtonOk(){
    if(this.globalColor.length != 0){
    let okButton = this.globalColor.records.filter(x => x.Name == "Button_OK");
    return okButton[0].Colors__c;
    }       
  }

  public getButtonCancel(){
    if(this.globalColor.length != 0){
    let cancelButton = this.globalColor.records.filter(x => x.Name == "Button_Cancel");
    return cancelButton[0].Colors__c; 
    }      
  }

  public AppointmentDelayThresholdTime(){
    this.AppointmentDelayThreshold  = 10;
    return this.AppointmentDelayThreshold;
  } 

  public getLeftSide(){
    if(this.globalColor.length != 0){
    let leftPanneBG = this.globalColor.records.filter(x => x.Name == "Left_Panel_BG");

    // console.log("Left PannelCoolor",leftPanneBG)
    return leftPanneBG[0].Colors__c; 
    } 
  }
  
  public getLeftPanelHighLight(){
    if(this.globalColor.length != 0){
    let LeftPanelHighLight = this.globalColor.records.filter(x => x.Name == "Left_Panel_Highlight");

    return LeftPanelHighLight[0].Colors__c; 
    } 
  } 

  public getFieldBG(){
    if(this.globalColor.length != 0){
    let getFieldBG = this.globalColor.records.filter(x => x.Name == "Field_BG");

    return getFieldBG[0].Colors__c; 
    } 
  }
  public getFieldFocus(){
    if(this.globalColor.length != 0){
    let Field_Border = this.globalColor.records.filter(x => x.Name == "Field_Border");

    return Field_Border[0].Colors__c; 
    } 
  }


  public getFontColor(){
    if(this.globalColor.length != 0){
    let Field_Border = this.globalColor.records.filter(x => x.Name == "Left_Panel_Highlight_Font");

    return Field_Border[0].Colors__c; 
    } 
  }
  
  

  public getSectionHeader(){
    if(this.globalColor.length != 0){
    let Section_Header = this.globalColor.records.filter(x => x.Name == "Section_Header");

    return Section_Header[0].Colors__c; 
    } 
  }
  
  public getMainPannel(){
      if(this.globalColor.length != 0){
      let mainPannelBG = this.globalColor.records.filter(x => x.Name == "Main_Panel_BG");
  
      // console.log("Left mainPannelBG",mainPannelBG)
      return mainPannelBG[0].Colors__c; 
   } 
  }
  public getFieldBorder(){
    if(this.globalColor.length != 0){
    let Field_Border = this.globalColor.records.filter(x => x.Name == "Field_Border");

    return Field_Border[0].Colors__c; 
    } 
  }

}
